import * as React from "react";

import {
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Typography,
	Dialog,
	Button,
	Slide,
	LinearProgress,
} from "@mui/material";

import { createStyles, makeStyles, Theme } from "@material-ui/core";

import {
	AlternateEmail,
	Person,
	Phone,
	SupervisorAccount,
	Refresh,
	Lock,
} from "@material-ui/icons";

import crypto from "crypto";

import { Section } from "../../components/Section/Section";
import { Title } from "../../components/Title/Title";

import { TransitionProps } from "@mui/material/transitions";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		wrapper: {
			padding: "1rem",
			borderRadius: 4,
			border: "1px solid rgba(224, 224, 224, 1)",
		},
		header: {
			display: "flex",
			alignItems: "center",
			marginBottom: "1rem",
		},
		grow: {
			display: "flex",
			flexGrow: 1,
		},
		desktopFieldPadding: {
			[theme.breakpoints.up("sm")]: {
				paddingRight: "1rem",
			},
		},
		input: {
			margin: "1rem 0",
		},
	})
);

interface IAddClientPageProps {
	id: string;
}

export const AddClientDialog: React.FC<IAddClientPageProps> = ({ id }) => {
	const classes = useStyles();
	const db = useFirestore();

	let organizations: any = useSelector(
		(state: any) => state.firestore.data?.organizations
	);
	let organization = organizations ? organizations[id] : {};

	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const defaultNewClient = {
		id: crypto.randomBytes(32).toString("hex"),
		name: "",
		email: "",
		contact: "",
		phoneNumber: "",
		sites: [],
		enabled: true,
	};

	const [newClientData, setNewClientData]: any =
		React.useState(defaultNewClient);

	interface INewClientData {
		id: string;
		name: string;
		email: string;
		contact: string;
		phoneNumber: string;
		sites: any;
		enabled: boolean;
	}

	// Dialog
	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// Text Fields
	const handleChange =
		(prop: keyof INewClientData) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setNewClientData({ ...newClientData, [prop]: event.target.value });
		};

	// Id rando
	const handleAssignRandomKey = (prop: string) => {
		setNewClientData({
			...newClientData,
			[prop]: crypto.randomBytes(32).toString("hex"),
		});
	};

	const handleAddClient = () => {
		setLoading(true);
		const doc = db.collection("organizations").doc(id);
		const oldClients = organization?.clients || [];
		doc
			.update({
				clients: [...oldClients, { ...newClientData }],
			})
			.then((res: any) => {
				setLoading(false);
				setNewClientData(defaultNewClient);
				handleClose();
			})
			.catch((e: any) => {
				setLoading(false);
			});
	};

	return (
		<div>
			{loading && <LinearProgress style={{ width: "100%" }} />}
			<div>
				<Button size="small" onClick={handleOpen}>
					Add Client
				</Button>
			</div>
			<div style={{ margin: "1rem" }}>
				<Dialog
					onClose={handleClose}
					open={open}
					TransitionComponent={Transition}
					style={{ margin: 0 }}
				>
					<Section style={{ margin: 0 }}>
						{/* //1     Header */}
						<Title
							title="Add New Client"
							button={<Button onClick={() => handleClose()}>Close</Button>}
						/>

						{/* //2     Contact Fields */}
						<Title title="Client Details" size="small" dense />
						<Section style={{ margin: 0 }}>
							<Grid container>
								{/* //3     Inputs */}
								<Grid
									item
									xs={12}
									sm={6}
									className={classes.desktopFieldPadding}
								>
									<Typography
										variant="overline"
										style={{ display: "flex", width: "100%" }}
										paragraph
									>
										Client Information
									</Typography>

									<FormControl fullWidth>
										<InputLabel htmlFor="outlined-adornment-amount">
											Client Name
										</InputLabel>
										<OutlinedInput
											disabled={loading}
											autoFocus
											className={classes.input}
											fullWidth
											value={newClientData.name}
											onChange={handleChange("name")}
											startAdornment={
												<InputAdornment position="start">
													<SupervisorAccount />
												</InputAdornment>
											}
										/>
									</FormControl>

									<FormControl fullWidth>
										<InputLabel htmlFor="outlined-adornment-amount">
											Client Id
										</InputLabel>
										<OutlinedInput
											disabled={loading}
											className={classes.input}
											fullWidth
											value={newClientData.id}
											onChange={handleChange("id")}
											startAdornment={
												<InputAdornment position="start">
													<Lock />
												</InputAdornment>
											}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => handleAssignRandomKey("id")}
													>
														{<Refresh />}
													</IconButton>
												</InputAdornment>
											}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Typography
										variant="overline"
										style={{ display: "flex", width: "100%" }}
										paragraph
									>
										Contact Information
									</Typography>

									<FormControl fullWidth>
										<InputLabel htmlFor="outlined-adornment-amount">
											Name
										</InputLabel>
										<OutlinedInput
											disabled={loading}
											className={classes.input}
											fullWidth
											value={newClientData.contact}
											onChange={handleChange("contact")}
											startAdornment={
												<InputAdornment position="start">
													<Person />
												</InputAdornment>
											}
										/>
									</FormControl>
									<FormControl fullWidth>
										<InputLabel htmlFor="outlined-adornment-amount">
											Email
										</InputLabel>

										<OutlinedInput
											disabled={loading}
											className={classes.input}
											fullWidth
											value={newClientData.email}
											onChange={handleChange("email")}
											startAdornment={
												<InputAdornment position="start">
													<AlternateEmail />
												</InputAdornment>
											}
										/>
									</FormControl>

									<FormControl fullWidth>
										<InputLabel htmlFor="outlined-adornment-amount">
											Phone Number
										</InputLabel>

										<OutlinedInput
											disabled={loading}
											className={classes.input}
											fullWidth
											value={newClientData.phoneNumber}
											onChange={handleChange("phoneNumber")}
											startAdornment={
												<InputAdornment position="start">
													<Phone />
												</InputAdornment>
											}
										/>
									</FormControl>
								</Grid>
							</Grid>
							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
								}}
							></div>
						</Section>
					</Section>
					<Button
						onClick={() => handleAddClient()}
						style={{ borderRadius: 0 }}
						variant="contained"
						color="primary"
						disabled={!newClientData.id || !newClientData.name || loading}
					>
						{" "}
						Add Client{" "}
					</Button>
				</Dialog>
			</div>
		</div>
	);
};
