import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { functions } from "../../firebase/firebase";

const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

export const fetchDevice: any = createAsyncThunk(
	"devices/fetchDevice",
	async ({
		api,
		buildingId,
		floorId,
		spaceId,
		id,
	}: {
		api: string;
		buildingId: string;
		floorId: string;
		spaceId: string;
		id: string;
	}) => {
		const response = await fetchEndpoint({ route: `/device/${id}`, api });
		const parsedResponse = JSON.parse(response.data);

		console.log(parsedResponse);

		if (parsedResponse?.id) {
			let lineage = { buildingId, floorId, spaceId };
			return Object.assign(parsedResponse, lineage);
		} else {
			return {};
		}
	}
);

export const devicesSlice = createSlice({
	name: "devices",
	initialState: {
		data: {},
		status: "",
		error: "",
	},
	reducers: {},
	extraReducers: {
		[fetchDevice.pending]: (state, action) => {
			state.status = "loading";
		},
		[fetchDevice.fulfilled]: (state: any, action: any) => {
			state.status = "succeeded";
			console.log(action.payload);
			if (action.payload?.id) {
				state.data[action.payload.id] = action.payload;
			}
		},
		[fetchDevice.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
	},
});

export default devicesSlice.reducer;
