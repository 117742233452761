import * as React from "react";

// MUI
import { Switch } from "react-router";

// AppBar / Tab Components
import DashboardDrawer from "./Dashboard/DashboardDrawer";

// Route Enhancers
import PrivateRoute from "../routes/components/PrivateRoute";

// Router Pages
import { SitesRouter } from "../routes/SitesRouter";
import { ClientsRouter } from "../routes/ClientsRouter";
import { UsersRouter } from "../routes/UsersRouter";
import { OrganizationsRouter } from "../routes/OrganizationsRouter";
import { WhitelabelsRouter } from "../routes/WhitelabelsRouter";
import { Monitor } from "./Monitor/Monitor";

// Custom hooks
import useGetProducts from "../hooks/useGetProducts";
import useGetLicenses from "../hooks/useGetLicenses";
import useGetWhitelabels from "../hooks/useGetWhitelabels";
import useGetOrganizations from "../hooks/useGetOrganizations";
import { PermissionsRouter } from "../routes/PermissionsRouter";

interface IDashboardProps {}

export const Dashboard: React.FC<IDashboardProps> = (props) => {
	// Load everything into firestore
	useGetProducts();
	useGetLicenses();
	useGetWhitelabels();
	useGetOrganizations();

	return (
		<div style={{ display: "flex", flexWrap: "wrap" }}>
			{/* //1	Drawer	(for formatting reasons, all pages rendered within drawer (to manage content)) */}
			<DashboardDrawer>
				<Switch>
					<PrivateRoute path="/dashboard/organizations">
						<OrganizationsRouter />
					</PrivateRoute>

					<PrivateRoute path="/dashboard/clients">
						<ClientsRouter />
					</PrivateRoute>

					<PrivateRoute path="/dashboard/permissions">
						<PermissionsRouter />
					</PrivateRoute>

					<PrivateRoute path="/dashboard/monitor">
						<Monitor />
					</PrivateRoute>

					<PrivateRoute path="/dashboard/users">
						<UsersRouter />
					</PrivateRoute>

					<PrivateRoute path="/dashboard/sites">
						<SitesRouter />
					</PrivateRoute>

					<PrivateRoute path="/dashboard/whitelabels">
						<WhitelabelsRouter />
					</PrivateRoute>
				</Switch>
			</DashboardDrawer>
		</div>
	);
};
