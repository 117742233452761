import * as React from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { IBuilding, ISite } from "../../interfaces";
import { isEmpty } from "lodash";
import {
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Typography,
} from "@mui/material";
import { ArrowRight, Edit, Info } from "@material-ui/icons";

interface IClientsInfoDialog {
	infoData:
		| {
				contact: string;
				email: string;
				id: string;
				name: string;
				phoneNumber: string;
				sites: ISite[];
		  }
		| any;

	handleClose: () => void;
}

export default function ClientsInfoDialog({
	infoData,
	handleClose,
}: IClientsInfoDialog) {
	const open = !isEmpty(infoData);

	const theme = useTheme();

	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	if (isEmpty(infoData)) return <span />;

	return (
		<div>
			<Dialog
				fullWidth={true}
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
			>
				<DialogTitle id="responsive-dialog-title">{infoData.name}</DialogTitle>
				<DialogContent>
					<ListItem
						disablePadding
						secondaryAction={
							infoData.phoneNumber ? (
								<ListItemText
									primary={"Phone"}
									secondary={infoData.phoneNumber}
								/>
							) : (
								<span />
							)
						}
					>
						<ListItemText
							primary={infoData.contact}
							secondary={infoData.email}
						/>
					</ListItem>

					<Divider style={{ marginBottom: "1rem" }} />

					<List subheader={<ListSubheader disableGutters>Sites</ListSubheader>}>
						{infoData?.sites.map((site: ISite) => (
							<ListItem
								disablePadding
								key={site?.id}
								secondaryAction={
									site.buildings?.length ? (
										<IconButton edge="end">
											<Edit />
										</IconButton>
									) : (
										<span />
									)
								}
							>
								<ListItemText
									primary={site?.name}
									secondary={`id: ${site?.id || "unknown"}, buildings: ${
										site.buildings?.length || "none"
									}`}
								/>
							</ListItem>
						))}
					</List>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
