import React from "react";

import { lighten } from "@material-ui/core";
import {
	amber,
	deepOrange,
	green,
	lightGreen,
	red,
} from "@material-ui/core/colors";

export const getColorValue = (value: string | number | null) => {
	let colorStart = "#00bc9b",
		colorEnd = "#5eaefd";

	// Good
	if (typeof value === "number") {
		if (value >= 90) {
			colorStart = green[300];
			colorEnd = lighten(colorStart, 0.3);
		}
		// Okay
		if (value < 90 && value >= 80) {
			colorStart = lightGreen[400];
			colorEnd = lighten(colorStart, 0.3);
		}
		// Warn
		if (value < 80 && value > 60) {
			colorStart = amber[500];
			colorEnd = lighten(colorStart, 0.3);
		}
		// Danger
		if (value <= 60 && value > 20) {
			colorStart = deepOrange[300];
			colorEnd = lighten(colorStart, 0.3);
		}
		// Fail
		if (value <= 20) {
			colorStart = red[300];
			colorEnd = lighten(colorStart, 0.3);
		}
	}

	return { colorStart, colorEnd };
};
