import * as React from "react";

// MUI
import {
	Grid,
	Typography,
	createStyles,
	makeStyles,
	Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		wrapper: {
			padding: "1rem",
			marginTop: "1rem",
		},
		container: {
			padding: "1rem",
			width: "100%",
		},
		headerButtonWrapper: {
			display: "flex",
			justifyContent: "flex-end",
			padding: "1rem",
		},
		// Generic
		spacer: {
			width: "100%",
			marginTop: "1rem",
		},
		flex: {
			display: "flex",
			flexWrap: "wrap",
			alignItems: "baseline",
		},
		fullWidth: {
			width: "100%",
		},
		spaceBetween: {
			justifyContent: "space-between",
		},
		displayStringWrapper: {
			display: "flex",
			alignItems: "center",
			fontSize: "3rem",
		},
		componentTitle: {
			fontSize: "1rem",
		},
		textData: {
			width: "100%",
			fontSize: "1.25rem",
			fontWeight: 500,
		},
		componentWrapper: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		pointWrapper: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			paddingRight: "2rem",
		},
		textWrapper: {
			display: "flex",
		},
	})
);

interface IIAQComponentCard {
	name: string;
	iaqComponent: any;
	icon: any;
}

export const IAQComponentCard = ({
	name,
	iaqComponent,
	icon,
}: IIAQComponentCard) => {
	const classes = useStyles();
	const { data } = iaqComponent;

	return (
		<Grid
			container
			style={{
				alignItems: "center",
				justifyContent: "center",
				margin: ".5rem 0",
			}}
		>
			<Grid item xs={3} className={classes.pointWrapper}>
				{icon}
				<Typography className={classes.componentTitle}>{name}</Typography>
			</Grid>
			<Grid item xs={9} className={classes.textWrapper}>
				<Typography className={classes.textData} align="right">
					{data.displayString}
				</Typography>
			</Grid>
		</Grid>
	);
};
