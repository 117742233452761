import {
	createStyles,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	MenuItem,
	Switch,
	TextField,
	Theme,
} from "@material-ui/core";
import {
	AlternateEmail,
	Person,
	Phone,
	SupervisorAccount,
	Refresh,
	Lock,
} from "@material-ui/icons";

import {
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Typography,
	Link,
} from "@mui/material";

import crypto from "crypto";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import * as React from "react";
import { useHistory } from "react-router";

import { AddSiteDialog } from "./AddSiteDialog";
import { Section } from "../../components/Section/Section";
import { Title } from "../../components/Title/Title";
import { GoBack } from "../../components/GoBack/GoBack";
import {
	green,
	indigo,
	lightBlue,
	lightGreen,
	orange,
	red,
} from "@material-ui/core/colors";

interface IAddClientPageProps {}

const orgAPI = [
	{
		name: "BrainBox",
		license: "Premium",
	},
	{
		name: "GSA",
		license: "Standard",
	},
	{
		name: "F1 Garage",
		license: "IAQ Dial",
	},
	{
		name: "Testing",
		license: "Testing",
	},
];

const whitelabelAPI = [
	{
		name: "Main",
		primary: green[500],
		secondary: indigo[500],
	},
	{
		name: "East Branch",
		primary: lightBlue[500],
		secondary: lightGreen[300],
	},
	{
		name: "West Branch",
		primary: orange[300],
		secondary: red[300],
	},
];

const licenses = [
	{
		value: "tapamini",
		label: "Executive",
	},
	{
		value: "tapaiaq",
		label: "Business",
	},
	{
		value: "tapamonitor",
		label: "Government",
	},
	{
		value: "tapatenant",
		label: "Distributer",
	},
];

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		wrapper: {
			padding: "1rem",
			borderRadius: 4,
			border: "1px solid rgba(224, 224, 224, 1)",
			marginBottom: "1rem",
		},
		header: {
			display: "flex",
			alignItems: "center",
			marginBottom: "1rem",
		},
		grow: {
			display: "flex",
			flexGrow: 1,
		},
		desktopFieldPadding: {
			[theme.breakpoints.up("sm")]: {
				paddingRight: "1rem",
			},
		},
		input: {
			margin: "1rem 0",
		},
	})
);

export const AddClientPage: React.FC<IAddClientPageProps> = (props) => {
	const classes = useStyles();
	const history = useHistory();

	const [whitelabel, setWhitelabel] = React.useState("Main");
	const [license, setLicense] = React.useState("tapamini");
	const [organization, setOrganization] = React.useState("BrainBox");

	const handleChangeOrganization = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setOrganization(event.target.value);
	};

	const handleChangeWhitelabel = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setWhitelabel(event.target.value);
	};

	const handleChangeLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLicense(event.target.value);
	};

	// Handlers
	const handleClick = () => {
		history.goBack();
	};

	const defaultNewClient = {
		id: "",
		name: "",
		email: "",
		contact: "",
		phoneNumber: "",
		sites: [],
	};

	const [newClientData, setNewClientData]: any =
		React.useState(defaultNewClient);

	interface INewClientData {
		id: string;
		name: string;
		email: string;
		contact: string;
		phoneNumber: string;
		sites: any;
	}

	const handleChange =
		(prop: keyof INewClientData) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setNewClientData({ ...newClientData, [prop]: event.target.value });
		};

	const handleAssignRandomKey = (prop: string) => {
		setNewClientData({
			...newClientData,
			[prop]: crypto.randomBytes(32).toString("hex"),
		});
	};

	const handleAddSite = (siteData: any) => {
		setNewClientData({
			...newClientData,
			sites: [...newClientData.sites, siteData],
		});
	};

	// Sites Columns
	const columns: GridColDef[] = [
		{ field: "name", headerName: "Site Name", width: 200 },
		{ field: "id", headerName: "ID", width: 350 },
	];

	return (
		<div>
			{/* //1     Header */}
			<Title title="Add New Client" button={<GoBack />} />

			<Title title="Organization" size="small" dense />
			<Section>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<List>
							<ListItem>
								<ListItemText primary="Add Client to ..." />
								<ListItemSecondaryAction>
									<TextField
										select
										label=""
										value={organization}
										helperText="Current license applied"
										onChange={handleChangeOrganization}
									>
										{orgAPI.map((option: any) => (
											<MenuItem key={option.name} value={option.name}>
												{option.name}
											</MenuItem>
										))}
									</TextField>
								</ListItemSecondaryAction>
							</ListItem>
						</List>
					</Grid>
				</Grid>
			</Section>

			{/* //2     Contact Fields */}
			<Title title="Client Details" size="small" dense />
			<Section>
				<Grid container>
					{/* //3     Inputs */}
					<Grid item xs={12} sm={6} className={classes.desktopFieldPadding}>
						<Typography
							variant="overline"
							style={{ display: "flex", width: "100%" }}
							paragraph
						>
							Client Information
						</Typography>

						<FormControl fullWidth>
							<InputLabel htmlFor="outlined-adornment-amount">
								Client Name
							</InputLabel>
							<OutlinedInput
								className={classes.input}
								fullWidth
								value={newClientData.name}
								onChange={handleChange("name")}
								startAdornment={
									<InputAdornment position="start">
										<SupervisorAccount />
									</InputAdornment>
								}
							/>
						</FormControl>

						<FormControl fullWidth>
							<InputLabel htmlFor="outlined-adornment-amount">
								Client Id
							</InputLabel>
							<OutlinedInput
								className={classes.input}
								fullWidth
								value={newClientData.id}
								onChange={handleChange("id")}
								startAdornment={
									<InputAdornment position="start">
										<Lock />
									</InputAdornment>
								}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => handleAssignRandomKey("id")}
										>
											{<Refresh />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Typography
							variant="overline"
							style={{ display: "flex", width: "100%" }}
							paragraph
						>
							Contact Information
						</Typography>

						<FormControl fullWidth>
							<InputLabel htmlFor="outlined-adornment-amount">Name</InputLabel>
							<OutlinedInput
								className={classes.input}
								fullWidth
								value={newClientData.contact}
								onChange={handleChange("contact")}
								startAdornment={
									<InputAdornment position="start">
										<Person />
									</InputAdornment>
								}
							/>
						</FormControl>
						<FormControl fullWidth>
							<InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>

							<OutlinedInput
								className={classes.input}
								fullWidth
								value={newClientData.email}
								onChange={handleChange("email")}
								startAdornment={
									<InputAdornment position="start">
										<AlternateEmail />
									</InputAdornment>
								}
							/>
						</FormControl>

						<FormControl fullWidth>
							<InputLabel htmlFor="outlined-adornment-amount">
								Phone Number
							</InputLabel>

							<OutlinedInput
								className={classes.input}
								fullWidth
								value={newClientData.phoneNumber}
								onChange={handleChange("phoneNumber")}
								startAdornment={
									<InputAdornment position="start">
										<Phone />
									</InputAdornment>
								}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Section>

			<Title title="License" size="small" dense />

			<Section>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<List>
							<ListItem>
								<ListItemText primary="License Type" />
								<ListItemSecondaryAction>
									<TextField
										select
										label=""
										value={license}
										helperText="Current license applied"
										onChange={handleChangeLicense}
									>
										{licenses.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</ListItemSecondaryAction>
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Whitelabel"
									secondary={
										<Link
											component="button"
											variant="body2"
											onClick={() => {
												history.push("/dashboard/whitelabels/create", {
													title: "Create New Whitelabel",
												});
											}}
										>
											Create New
										</Link>
									}
								/>
								<ListItemSecondaryAction>
									<TextField
										select
										label=""
										value={whitelabel}
										helperText="Current whitelabel applied"
										onChange={handleChangeWhitelabel}
									>
										{whitelabelAPI.map((option) => (
											<MenuItem key={option.name} value={option.name}>
												{option.name}{" "}
											</MenuItem>
										))}
									</TextField>{" "}
								</ListItemSecondaryAction>
							</ListItem>
						</List>
					</Grid>
				</Grid>
			</Section>

			{/* //4     Add Sites */}

			<Title title="Add Sites" size="small" dense />
			<Section>
				<div
					style={{
						display: "flex",
						width: "100%",
						justifyContent: "end",
						marginBottom: "1rem",
					}}
				>
					{/* //! Add Site Dialog */}
					<AddSiteDialog handleAddSite={handleAddSite} />
				</div>

				{/* Sites Table */}
				<div style={{ height: 300, width: "100%" }}>
					<DataGrid
						rows={newClientData.sites}
						columns={columns}
						pageSize={5}
						rowsPerPageOptions={[5]}
						isCellEditable={() => false}
						disableSelectionOnClick={true}
					/>
				</div>
			</Section>
		</div>
	);
};
