import * as React from "react";

// Mui
import {
	Box,
	Typography,
	IconButton,
	// Styles
	createStyles,
	makeStyles,
	lighten,
	Theme,
} from "@material-ui/core";

import { CircularProgress } from "@mui/material";

// Icons
import { Refresh } from "@material-ui/icons";

// Util
import clsx from "clsx";
import { getColorValue } from "../../util";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		glass: {
			display: "inline-flex",
			backgroundColor: "transparent",
			backgroundImage:
				"linear-gradient(to bottom right, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0))",
			backdropFilter: "blur(7px)",
			boxShadow: "10px 10px 10px rgba(30, 30, 30, .1)",
			borderRadius: "100%",
		},
		fullWidth: {
			width: "100%",
		},
		svgCircle: {
			color: (props: any) => props.url,
			stroke: (props: any) => props.url,
			strokeLinecap: "round",
		},
		shadows: {
			boxShadow:
				"inset -3px -3px 10px -10px rgba(255, 255, 255, 1), inset 0px 0px 10px rgba(13, 39, 80, .3)",
			filter: "drop-shadow(-2px -2px 4px rgba(255, 255, 255, .33))",
		},
		valueSuffix: {
			fontSize: "1.65rem",
		},
	})
);

// If min/max provided, normalize range
const normalize = (value: number, min: number, max: number) =>
	((value - min) * 100) / (max - min);

interface IGlassDial {
	id: any;
	value: number | string;
	valueSuffix?: number | string;
	appendToValue?: string;
	label?: string;
	min?: number;
	max?: number;
	color?: string;
	colorByValue?: boolean;
	size?: number;
	style?: any;
	className?: any;
	mobile?: boolean;
	fontSize?: string | number;
	labelFontSize?: string | number;
	handleClick?: any;
	loading?: boolean;
}

export const GlassDial: React.FC<IGlassDial> = (props) => {
	// Props
	const {
		id,
		value,
		valueSuffix,
		appendToValue,
		label,
		color = "",
		colorByValue = false,
		size,
		min = 0,
		max = 100,
		mobile,
		fontSize = "2rem",
		labelFontSize = ".75rem",
		handleClick = null,
		loading = false,
	} = props;

	// Safari Fix
	const url = `url(${
		window.location.protocol +
		"//" +
		window.location.host +
		window.location.pathname +
		window.location.search +
		"#" +
		id
	}) !important`;

	const classes = useStyles({ url });

	// Create default gradient
	let colorStart = "";
	let colorEnd = "";

	// Tune color if given
	if (color) {
		colorStart = color;
		colorEnd = lighten(color, 0.3);
	}

	// Create colorByValue
	if (colorByValue && Boolean(!color)) {
		let colorValues = getColorValue(value);
		colorStart = colorValues.colorStart;
		colorEnd = colorValues.colorEnd;
	}

	// Default if none given
	if (!colorByValue && Boolean(!color)) {
		let colorValues = getColorValue(null);
		colorStart = colorValues.colorStart;
		colorEnd = colorValues.colorEnd;
	}

	let normalizedValue;
	if (Boolean(min || max) && typeof value === "number") {
		if (!Boolean(isNaN(Number(value)))) {
			normalizedValue = normalize(value, min, max);
		}
	}

	// Button
	let isButton = false;
	if (typeof value === "boolean" && handleClick) {
		isButton = true;
	}

	return (
		<div
			className={clsx(classes.glass)}
			style={{ padding: mobile ? 0 : "1rem" }}
		>
			<Box position="relative" display="inline-flex">
				{/* Load gradient in SVG */}
				<svg
					xmlnsXlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					width={size ? size : 100}
					height={size ? size : 100}
					style={{ position: "absolute" }}
					xmlns="http://www.w3.org/2000/svg"
				>
					<defs>
						<linearGradient id={id} x1="100%" y1="50%" x2="0%" y2="50%">
							<stop offset="0%" stopColor={colorStart} />
							<stop offset="100%" stopColor={colorEnd} />
						</linearGradient>
					</defs>
				</svg>

				{/* Progress with glass, shadows, and ability to normalize value */}
				<CircularProgress
					size={size ? size : 100}
					variant={loading ? "indeterminate" : "determinate"}
					thickness={2}
					value={
						normalizedValue
							? normalizedValue
							: typeof value === "number"
							? value
							: 0
					}
					className={clsx(classes.glass, classes.shadows)}
					classes={{
						svg: classes.svgCircle,
						colorPrimary: classes.svgCircle,
						circle: classes.svgCircle,
						circleDeterminate: classes.svgCircle,
						circleIndeterminate: classes.svgCircle,
					}}
				/>

				{/* Value display and label */}
				<Box
					top={0}
					left={0}
					bottom={0}
					right={0}
					position="absolute"
					display="flex"
					flexWrap="wrap"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					{/* Value */}

					<Typography
						style={{ fontSize, lineHeight: 1 }}
						variant="caption"
						component="div"
						color="textSecondary"
					>
						{typeof value === "number" && !Boolean(isNaN(value)) ? (
							// 1		Number Mode				//
							`${Math.round(value)}`
						) : Boolean(isButton) ? (
							// 2		Button Mode				//
							<IconButton
								onClick={() => handleClick()}
								style={{ height: size, width: size }}
							>
								{value ? "On" : "Off"}
							</IconButton>
						) : (
							// 3		Refresh On No Value		//
							<IconButton onClick={() => window.location.reload()}>
								<Refresh />
							</IconButton>
						)}

						{/* Small text after value (density) */}
						{Boolean(valueSuffix !== void 0) && (
							<span className={classes.valueSuffix}>{valueSuffix}</span>
						)}

						{/* Units / Degree Symbol */}
						{Boolean(appendToValue) && appendToValue}
					</Typography>

					{/* If Label, show */}
					{Boolean(label) && (
						<Typography
							variant="overline"
							component="div"
							color="textSecondary"
						>
							{label}
						</Typography>
					)}
				</Box>
			</Box>
		</div>
	);
};
