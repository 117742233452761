import React from "react";

// Firebase
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

// Util
import { isEmpty } from "lodash";

const useGetWhitelabels = () => {
	const [whitelabels, setWhitelabels]: any = React.useState([]);

	useFirestoreConnect(["whitelabels"]);
	let firestoreWhitelabels: any =
		useSelector((state: any) => state.firestore.data?.whitelabels) || {};

	React.useEffect(() => {
		if (firestoreWhitelabels && !isEmpty(firestoreWhitelabels)) {
			let whitelabelValues = [];
			whitelabelValues = Object.keys(firestoreWhitelabels).map(
				(whitelabel: any) => ({
					...firestoreWhitelabels[whitelabel],
					id: whitelabel,
				})
			);
			setWhitelabels(whitelabelValues);
		}
	}, [firestoreWhitelabels]);

	return whitelabels;
};

export default useGetWhitelabels;
