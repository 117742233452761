import React, { useEffect } from "react";

import { difference } from "lodash";

// Redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/hooks";
import { fetchBuilding } from "../features/buildings/buildingsSlice";

import useBuildingList from "./useBuildingList";

const useFetchBuildings = () => {
	const dispatch = useDispatch();
	const api = useAppSelector((state: any) => state.firebase.profile.api);

	const buildingStore = useAppSelector((state: any) => state.buildings);
	const buildingList = useBuildingList();

	// Get IDS from a client's buildings, and building data already stored in Redux
	const buildingListIds = buildingList?.map((building: any) => building.id);
	const buildingStoreIds = Object.values(buildingStore?.data).map(
		(building: any) => building?.id
	);

	// When all buildings are fetched into Redux, fetchBuildings === []
	let fetchBuildings = difference(buildingListIds, buildingStoreIds);

	useEffect(() => {
		if (buildingStore.status !== "loading" && fetchBuildings.length) {
			fetchBuildings.forEach((id: any) => {
				dispatch(fetchBuilding({ api, id }));
			});
		}
		//
	}, [buildingList.length]);

	return !Boolean(fetchBuildings.length);
};

export default useFetchBuildings;
