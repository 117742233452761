import * as React from "react";

import {
	Button,
	createStyles,
	LinearProgress,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Switch,
	Theme,
	Typography,
	useTheme,
} from "@material-ui/core";
import { Grid } from "@mui/material";

import { Section } from "../../components/Section/Section";

import { useAppSelector } from "../../app/hooks";
import { useHistory, useRouteMatch } from "react-router";

import { AlternateEmail, Edit, Person, Phone } from "@material-ui/icons";
import { Title } from "../../components/Title/Title";
import { GoBack } from "../../components/GoBack/GoBack";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			padding: "1rem",
			borderRadius: 4,
			border: "1px solid rgba(224, 224, 224, 1)",
			backgroundColor: theme.palette.background.paper,
		},
	})
);

interface IClientPageProps {}

export const ClientPage: React.FC<IClientPageProps> = (props) => {
	const classes = useStyles();

	const clients = useAppSelector((state: any) => state.clients);

	// Hooks
	const history = useHistory();
	const route: any = useRouteMatch();
	const theme = useTheme();

	// Params
	const clientId = route?.params?.clientId;

	// Redirect to main if no id found
	if (!clientId) history.goBack();

	const client = clients.data[clientId];

	// Display loading progress bar
	if (clients.status === "loading" || !clients.status) {
		return (
			<Section>
				<LinearProgress />
			</Section>
		);
	}

	// Display error if no client found
	if (!client)
		return (
			<Section>
				<Typography variant="h6" align="center">
					Client could not be found
				</Typography>
			</Section>
		);

	// Handlers
	const handleSiteClick = (siteId: string, name: string) => {
		history.push(`/dashboard/clients/${clientId}/site/${siteId}`, {
			title: `${client.name} / ${name}`,
		});
	};

	return (
		<div>
			{/* //1     Contact      */}
			<Title title="Contact" size="small" button={<GoBack />} />
			<Section disablePadding>
				<List style={{ display: "flex" }}>
					<ListItem dense>
						<ListItemIcon>
							<Person />
						</ListItemIcon>
						<ListItemText primary={client.contact} secondary={"Contact"} />
					</ListItem>

					<ListItem dense>
						<ListItemIcon>
							<Phone />
						</ListItemIcon>
						<ListItemText primary={client.phoneNumber} secondary={"Phone"} />
					</ListItem>

					<ListItem dense>
						<ListItemIcon>
							<AlternateEmail />
						</ListItemIcon>
						<ListItemText primary={client.email} secondary={"Email"} />
					</ListItem>
				</List>
			</Section>

			{/* //2     Sites        */}
			<Title
				title="Sites"
				size="small"
				button={
					<Button
						color="primary"
						style={{ width: "100%", justifyContent: "end" }}
					>
						Add Site
					</Button>
				}
			/>
			<Section variant="gray">
				<Grid container>
					{client?.sites?.map((site: any) => (
						<Grid
							item
							key={site?.id}
							xs={6}
							sm={4}
							style={{ padding: ".5rem" }}
						>
							<List dense disablePadding>
								<ListItem
									dense
									button
									className={classes.wrapper}
									onClick={() => handleSiteClick(site.id, site.name)}
								>
									<ListItemText primary={site.name} secondary={site.id} />
								</ListItem>
								<Link
									component="button"
									variant="body2"
									style={{
										display: "flex",
										justifyContent: "end",
										width: "100%",
									}}
								>
									Edit
								</Link>
							</List>
						</Grid>
					))}
				</Grid>
			</Section>

			{/* //3     Features        */}
			<Title title="Features" size="small" />
			<Section disablePadding>
				<Grid container>
					<Grid item xs={6}>
						<List>
							<ListItem>
								<ListItemText primary="IAQ" />
								<ListItemSecondaryAction>
									<Switch />
								</ListItemSecondaryAction>
							</ListItem>
							<ListItem>
								<ListItemText primary="Monitor" />
								<ListItemSecondaryAction>
									<Switch />
								</ListItemSecondaryAction>
							</ListItem>
							<ListItem>
								<ListItemText primary="Admin" />
								<ListItemSecondaryAction>
									<Switch />
								</ListItemSecondaryAction>
							</ListItem>
							<ListItem>
								<ListItemText primary="Tenant" />
								<ListItemSecondaryAction>
									<Switch />
								</ListItemSecondaryAction>
							</ListItem>
						</List>
					</Grid>
				</Grid>
			</Section>
		</div>
	);
};
