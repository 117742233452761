import * as React from "react";

// MUI
import {
	Box,
	Drawer,
	Button,
	List,
	Divider,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	AppBar,
	Toolbar,
	Typography,
	Link,
	TextField,
	ListItemSecondaryAction,
	Fab,
} from "@mui/material";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Section } from "../../components/Section/Section";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { Add } from "@mui/icons-material";
import { ConditionsDrawer } from "./ConditionsDrawer";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawerPaper: {
			width: "60%",
		},
		section: {
			marginBottom: "1rem",
		},
	})
);

interface IPermissionsDrawerProps {
	open: boolean;
	handleClose: () => void;
}

interface IRole {
	name: string;
	description?: string;
}

export const PermissionsDrawer: React.FC<IPermissionsDrawerProps> = ({
	open,
	handleClose,
}) => {
	const classes = useStyles();
	const clients = useAppSelector((state: RootState) => state.clients);

	const [conditions, setConditions]: any = React.useState([]);
	const defaultRole = {
		name: "Some Role",
		description: "Some role description",
	};
	const [role, setRole] = React.useState<IRole>(defaultRole);

	// Handlers
	const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRole({ ...role, [event.target.name]: event.target.value });
	};

	const handleAddCondition = (condition: any) => {
		setConditions([...conditions, condition]);
	};

	console.log(conditions);

	return (
		<div>
			<Drawer
				anchor={"right"}
				open={open}
				onClose={handleClose}
				classes={{ paper: classes.drawerPaper }}
			>
				{/* //.     Header       */}
				<Toolbar>
					<Typography variant="h6">Add Permissions Role</Typography>
				</Toolbar>
				<Divider />

				{/* //.     Instructions         */}
				<Section style={{ paddingBottom: 0 }}>
					<div className={classes.section}>
						<Typography variant="body1" paragraph>
							Create a role with permissions to assign a user to.
							<Link>Learn more</Link>. If you would like to add a user with
							custom permissions, <Link>click here</Link> to add a user.
						</Typography>
					</div>

					<div>
						<TextField
							required
							fullWidth
							id="outlined-basic"
							label="Role Name"
							variant="outlined"
							name="name"
							onChange={handleChangeText}
							margin="dense"
						/>
					</div>

					<div className={classes.section}>
						<TextField
							fullWidth
							id="description-basic"
							label="Description"
							variant="outlined"
							name="description"
							onChange={handleChangeText}
							multiline
							rows={3}
							margin="dense"
						/>
					</div>

					<div className={classes.section}>
						<List dense disablePadding>
							{/* Add Condition */}
							<ListItem dense>
								<ListItemText
									primary="Add Condition"
									secondary="Add a resource to assign a permission to"
								/>
								<ListItemSecondaryAction>
									<ConditionsDrawer handleAddCondition={handleAddCondition} />
								</ListItemSecondaryAction>
							</ListItem>

							{/* Conditions */}
							{/*  */}
						</List>
					</div>
				</Section>
				<Divider />
			</Drawer>
		</div>
	);
};
