import * as React from "react";

import {
	Box,
	Button,
	createStyles,
	LinearProgress,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Switch,
	Theme,
	Typography,
	useTheme,
} from "@material-ui/core";
import { Grid } from "@mui/material";

import { Section } from "../../components/Section/Section";

import { useAppSelector } from "../../app/hooks";
import { useHistory, useRouteMatch } from "react-router";

import { AlternateEmail, Edit, Person, Phone } from "@material-ui/icons";
import { Title } from "../../components/Title/Title";
import { GoBack } from "../../components/GoBack/GoBack";
import { find } from "lodash";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			padding: "1rem",
			borderRadius: 4,
			border: "1px solid rgba(224, 224, 224, 1)",
			backgroundColor: theme.palette.background.paper,
		},
	})
);

interface IClientSitePageProps {}

export const ClientSitePage: React.FC<IClientSitePageProps> = (props) => {
	const classes = useStyles();

	const clients = useAppSelector((state: any) => state.clients);

	// Hooks
	const history = useHistory();
	const route: any = useRouteMatch();
	const theme = useTheme();

	// Params
	const clientId = route?.params?.clientId;
	const siteId = route?.params?.siteId;

	// Redirect to main if no id found
	if (!clientId || !siteId) history.goBack();

	const client = clients.data[clientId];
	const site =
		client?.sites && find(client.sites, (site: any) => site?.id === siteId);

	console.log(site);
	// Display loading progress bar
	if (clients.status === "loading" || !clients.status) {
		return (
			<Section>
				<LinearProgress />
			</Section>
		);
	}

	// Display error if no client found
	if (!site)
		return (
			<Section>
				<Typography variant="h6" align="center">
					Site could not be found
				</Typography>
			</Section>
		);

	// Handlers
	const handleSiteClick = (siteId: string, name: string) => {
		history.push(`/dashboard/clients/${clientId}/site/${siteId}`, {
			title: `${client.name} / ${name}`,
		});
	};

	// Status Icon
	const bull = (
		<Box
			component="span"
			style={{
				zIndex: 9,
				position: "absolute",
				top: 0,
				right: 0,
				fontSize: 24,
				color: green[500],
				padding: "0 .5rem",
			}}
		>
			•
		</Box>
	);

	return (
		<div>
			{/* //1     Contact      */}
			<Title title="Site Details" size="small" button={<GoBack />} />
			<Section disablePadding>
				<List style={{ display: "flex" }}>
					<ListItem dense>
						<ListItemIcon>
							<Person />
						</ListItemIcon>
						<ListItemText primary={client.contact} secondary={"Contact"} />
					</ListItem>

					<ListItem dense>
						<ListItemIcon>
							<Phone />
						</ListItemIcon>
						<ListItemText primary={client.phoneNumber} secondary={"Phone"} />
					</ListItem>

					<ListItem dense>
						<ListItemIcon>
							<AlternateEmail />
						</ListItemIcon>
						<ListItemText primary={client.email} secondary={"Email"} />
					</ListItem>
				</List>
			</Section>

			{/* //2     Sites        */}
			<Title
				title="Buildings"
				size="small"
				button={
					<Button
						color="primary"
						style={{ width: "100%", justifyContent: "end" }}
					>
						Add Building
					</Button>
				}
			/>
			<Section variant="gray">
				<Grid container>
					{site?.buildings?.map((building: any) => (
						<Grid
							item
							key={building?.id}
							xs={6}
							sm={4}
							style={{ padding: ".5rem" }}
						>
							<List dense disablePadding style={{ position: "relative" }}>
								{bull}
								<ListItem
									dense
									button
									className={classes.wrapper}
									onClick={() => handleSiteClick(building.id, building.name)}
								>
									<ListItemText primary={building.name} secondary={site.id} />
								</ListItem>
								<div
									style={{
										display: "flex",
										flexWrap: "wrap",
										width: "100%",
										justifyContent: "end",
									}}
								>
									<Link
										component="button"
										variant="body2"
										style={{
											display: "flex",
											marginRight: "1rem",
										}}
									>
										Scan
									</Link>

									<Link
										component="button"
										variant="body2"
										style={{
											display: "flex",
										}}
									>
										Edit
									</Link>
								</div>
							</List>
						</Grid>
					))}
				</Grid>
			</Section>

			{/* //3     Features        */}
			<Title title="Features" size="small" />
			<Section disablePadding>
				<Grid container>
					<Grid item xs={6}>
						<List>
							<ListItem>
								<ListItemText primary="IAQ" />
								<ListItemSecondaryAction>
									<Switch />
								</ListItemSecondaryAction>
							</ListItem>
							<ListItem>
								<ListItemText primary="Monitor" />
								<ListItemSecondaryAction>
									<Switch />
								</ListItemSecondaryAction>
							</ListItem>
							<ListItem>
								<ListItemText primary="Admin" />
								<ListItemSecondaryAction>
									<Switch />
								</ListItemSecondaryAction>
							</ListItem>
							<ListItem>
								<ListItemText primary="Tenant" />
								<ListItemSecondaryAction>
									<Switch />
								</ListItemSecondaryAction>
							</ListItem>
						</List>
					</Grid>
				</Grid>
			</Section>
		</div>
	);
};
