import * as React from "react";

import { Switch } from "react-router";
import PrivateRoute from "./components/PrivateRoute";

import { Whitelabels } from "../pages/Whitelabels/Whitelabels";
import { WhitelabelCreate } from "../pages/Whitelabels/WhitelabelCreate";

interface IWhitelabelsPageProps {}

export const WhitelabelsRouter: React.FC<IWhitelabelsPageProps> = (props) => {
	return (
		<div>
			<Switch>
				<PrivateRoute exact path="/dashboard/whitelabels">
					<Whitelabels />
				</PrivateRoute>

				<PrivateRoute exact path="/dashboard/whitelabels/create">
					<WhitelabelCreate />
				</PrivateRoute>
			</Switch>
		</div>
	);
};
