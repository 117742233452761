import React, { useState } from "react";

// Redux
import { RootState } from "../app/store";
import { useAppSelector } from "../app/hooks";

// Util
import { orderBy } from "lodash";

// Get list of buildings across clients
const generateDeviceList: any = (buildingStore: any) => {
	let deviceArray: any = [];

	Object.values(buildingStore).forEach((building: any) => {
		building?.floors?.forEach((floor: any) => {
			floor?.spaces?.forEach((space: any) => {
				space?.devices?.forEach((device: any) => {
					deviceArray.push({
						...device,
						buildingId: building?.id,
						buildingName: building?.name,
						floorId: floor?.id,
						floorName: floor?.name,
						spaceId: space?.id,
						spaceName: space?.name,
					});
				});
			});
		});
	});

	return orderBy(deviceArray, ["name"], ["asc"]);
};

const useDeviceList = () => {
	const [deviceList, setDeviceList]: any = useState([]);

	const buildingStore = useAppSelector(
		(state: RootState) => state.buildings.data
	);

	React.useEffect(() => {
		setDeviceList(generateDeviceList(buildingStore));
	}, [Object.values(buildingStore).length]);

	return deviceList;
};

export default useDeviceList;
