import * as React from "react";

import {
	DataGrid,
	GridColDef,
	GridRowParams,
	GridValueGetterParams,
} from "@mui/x-data-grid";

import { useHistory } from "react-router";
import { Typography, useTheme } from "@mui/material";

import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

interface IWhitelabelsTableProps {}

export const WhitelabelsTable: React.FC<IWhitelabelsTableProps> = (props) => {
	const history = useHistory();
	const theme = useTheme();

	const whitelabels = useAppSelector(
		(state: RootState) => state.firestore?.ordered?.whitelabels
	);

	// Table identity
	const columns: GridColDef[] = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "description", headerName: "Description", width: 200 },
		{ field: "tagline", headerName: "Tagline", width: 200 },
		{
			field: "primary",
			headerName: "Primary",
			width: 130,
			description: "Primary color.",
			sortable: false,
			renderCell: (params: GridValueGetterParams) => {
				console.log(params);
				return (
					<Typography style={{ color: params.row.primary || "inherit" }}>
						{params.row.primary || ""}
					</Typography>
				);
			},
		},
		{
			field: "secondary",
			headerName: "Secondary",
			width: 130,
			description: "Secondary color.",
			sortable: false,
			renderCell: (params: GridValueGetterParams) => {
				return (
					<Typography style={{ color: params.row.secondary || "inherit" }}>
						{params.row?.secondary || ""}
					</Typography>
				);
			},
		},
	];

	let rows: any = whitelabels;

	// Handlers
	const onRowClick = (params: GridRowParams) => {
		history.push(`/dashboard/whitelabels/${params.row.id}`, {
			title: params.row.name,
		});
	};

	if (!whitelabels?.length) return <span />;

	return (
		<div style={{ height: 350 }}>
			<DataGrid
				autoHeight
				rows={rows}
				columns={columns}
				pageSize={5}
				rowsPerPageOptions={[5]}
				isCellEditable={() => false}
				disableSelectionOnClick={true}
				onRowClick={onRowClick}
			/>
		</div>
	);
};
