import React from "react";

// Firebase
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

// Util
import { isEmpty } from "lodash";

const useGetProducts = () => {
	const [products, setProducts]: any = React.useState([]);

	useFirestoreConnect(["products"]);
	let firestoreProducts: any =
		useSelector((state: any) => state.firestore.data?.products) || {};

	React.useEffect(() => {
		if (firestoreProducts && !isEmpty(firestoreProducts)) {
			let productValues = [];
			productValues = Object.keys(firestoreProducts).map((product: any) => ({
				...firestoreProducts[product],
				id: product,
			}));
			setProducts(productValues);
		}
	}, [firestoreProducts]);

	return products;
};

export default useGetProducts;
