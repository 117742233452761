import * as React from "react";
import { useHistory } from "react-router";
import { Button } from "@material-ui/core";

interface IBackButtonProps {}

export const GoBack: React.FC<IBackButtonProps> = (props) => {
	const history = useHistory();

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "end",
				width: "100%",
				marginBottom: ".5rem",
			}}
		>
			<Button size="small" variant="outlined" onClick={() => history.goBack()}>
				Go Back
			</Button>
		</div>
	);
};
