import * as React from "react";

// MUI
import { Button, Typography } from "@mui/material";

// Icons
import { PersonAdd, PersonRemove } from "@mui/icons-material";

// Components
import { Section } from "../../components/Section/Section";
import { Title } from "../../components/Title/Title";
import { OrganizationsTable } from "../Organizations/OrganizationsTable";
import { GridRowParams } from "@mui/x-data-grid";

// Routing
import { useHistory } from "react-router";

interface IPermissionsProps {}

export const Permissions: React.FC<IPermissionsProps> = (props) => {
	const history = useHistory();

	const onRowClick = (params: GridRowParams) => {
		history.push(`/dashboard/permissions/${params.row.id}`, {
			title: params.row.name,
		});
	};

	return (
		<Section>
			<Title title="Organization Permissions" />
			<Typography paragraph variant="body1">
				Select an organization to set up permissions
			</Typography>

			<OrganizationsTable rowClick={onRowClick} />
		</Section>
	);
};
