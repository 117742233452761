import * as React from "react";

import {
	Divider,
	Grid,
	LinearProgress,
	Link,
	MenuItem,
	Switch,
	TextField,
	Typography,
} from "@material-ui/core";

import {
	Button,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from "@mui/material";

import {
	green,
	indigo,
	lightBlue,
	lightGreen,
	orange,
	red,
} from "@material-ui/core/colors";

import { differenceBy, find, isEmpty } from "lodash";

import { useSelector } from "react-redux";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useHistory, useParams } from "react-router";

import { Section } from "../../components/Section/Section";
import { Title } from "../../components/Title/Title";
import { useLoading } from "../../hooks/useLoading";

// Hooks
import useGetLicenses from "../../hooks/useGetLicenses";
import useGetWhitelabels from "../../hooks/useGetWhitelabels";
import useGetProducts from "../../hooks/useGetProducts";
import { AddClientDialog } from "../Clients/AddClientDialog";
import { OrganizationClientsTable } from "./OrganizationClientsTable";
import { Add } from "@material-ui/icons";
import { AddLicenseDialog } from "../Licenses/AddLicenseDialog";
import { AddWhitelabelDialog } from "../Whitelabels/AddWhitelabelDialog";

interface IOrganizationPageProps {}

export const OrganizationPage: React.FC<IOrganizationPageProps> = (props) => {
	const db = useFirestore();

	const history = useHistory();
	const params: any = useParams();

	const clients = useSelector((state: any) => state.clients);
	const clientData = Object.values(clients.data);

	const isLoading = useLoading("clients");

	// .		Organization data
	useFirestoreConnect(["organizations"]);
	let organizations: any = useSelector(
		(state: any) => state.firestore.data?.organizations
	);
	let organizationId = params?.id || "";
	let organization = organizations ? organizations[organizationId] : {};

	// Org in firestore
	const doc = db.collection("organizations").doc(organizationId);

	// . 		License Data
	const licenseValues = useGetLicenses();
	let currentLicense = find(licenseValues, ["id", organization?.license?.id]);

	// . 		Whitelabel Data
	const whitelabelValues = useGetWhitelabels();
	let currentWhitelabel = find(whitelabelValues, [
		"id",
		organization?.whitelabel?.id,
	]);

	// .		Product Data
	const productValues = useGetProducts();

	// -		Handlers
	// .		License
	const handleChangeLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
		doc.update({
			license: {
				id: event.target.value,
				enabled: organization?.license?.enabled || false,
			},
		});
	};

	const handleEnableLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
		doc.update({
			license: {
				id: organization?.license?.id,
				enabled: !organization?.license?.enabled,
			},
		});
	};

	// .		Whitelabel
	const handleChangeWhitelabel = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		doc.update({
			whitelabel: {
				id: event.target.value,
				enabled: organization?.license?.enabled || false,
			},
		});
	};

	const handleEnableWhitelabel = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		doc.update({
			whitelabel: {
				id: organization?.whitelabel?.id,
				enabled: !organization?.whitelabel?.enabled,
			},
		});
	};

	const handleAddProduct = (product: any) => {
		let prods = organization?.products || [];
		doc.update({ products: [...prods, { ...product, enabled: false }] });
	};

	const handleRemoveProduct = (id: string) => {
		let prods = organization?.products.filter((prod: any) => {
			return prod.id !== id;
		});

		doc.update({ products: prods });
	};

	const handleUpdateProducts = (id: string) => {
		let prods = organization?.products.map((prod: any) => {
			return prod.id === id ? { ...prod, enabled: !prod.enabled } : { ...prod };
		});

		doc.update({ products: prods });
	};

	// !		Setup
	if (isLoading || !clientData || !params?.id || isEmpty(organizations))
		return (
			<Section>
				<LinearProgress />
			</Section>
		);

	if (!organization) {
		return (
			<Section>
				<Title dense title="Organization not found" size="small" />
			</Section>
		);
	}
	// !		Setup

	return (
		<div>
			<Title dense title="Organization Details" size="small" />

			{/* //1     General Options */}

			<Section disablePadding>
				<Grid container>
					{/* //-		License		 */}
					<Grid item xs={6}>
						<List>
							<ListItem>
								<ListItemText primary="License Enabled" />
								<ListItemSecondaryAction>
									<Switch
										checked={Boolean(organization?.license?.enabled)}
										value={Boolean(organization?.license?.enabled)}
										onChange={handleEnableLicense}
									/>
								</ListItemSecondaryAction>
							</ListItem>

							<ListItem>
								<ListItemText
									primary="License Type"
									secondary={<AddLicenseDialog />}
								/>
								{Boolean(licenseValues?.length) && (
									<ListItemSecondaryAction>
										<TextField
											disabled={Boolean(!organization?.license?.enabled)}
											select
											label=""
											value={currentLicense?.id || ""}
											helperText={
												Boolean(!organization?.license?.enabled)
													? "License is disabled"
													: "Current license applied"
											}
											onChange={handleChangeLicense}
										>
											{licenseValues.map((option: any) => (
												<MenuItem key={option.value} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</TextField>
									</ListItemSecondaryAction>
								)}
							</ListItem>

							<ListItem>
								<ListItemText primary="Whitelabel Enabled" />
								<ListItemSecondaryAction>
									<Switch
										checked={Boolean(organization?.whitelabel?.enabled)}
										value={Boolean(organization?.whitelabel?.enabled)}
										onChange={handleEnableWhitelabel}
									/>
								</ListItemSecondaryAction>
							</ListItem>

							<ListItem>
								<ListItemText
									primary="Whitelabel Selected"
									secondary={<AddWhitelabelDialog />}
								/>
								<ListItemSecondaryAction>
									<TextField
										disabled={Boolean(!organization?.whitelabel?.enabled)}
										select
										label=""
										value={currentWhitelabel?.id || ""}
										helperText={
											Boolean(!organization?.whitelabel?.enabled)
												? "Whitelabel disabled"
												: "Current whitelabel applied"
										}
										onChange={handleChangeWhitelabel}
									>
										{whitelabelValues.map((option: any) => (
											<MenuItem key={option.id} value={option.id}>
												{option.name}{" "}
											</MenuItem>
										))}
									</TextField>{" "}
								</ListItemSecondaryAction>
							</ListItem>

							<Divider style={{ marginTop: "1rem" }} />

							<ListItem>
								<ListItemText primary={"Active Users"} />
								<ListItemSecondaryAction
									style={{ display: "flex", alignItems: "center" }}
								>
									<Typography variant="body1">8</Typography>
									<Typography variant="body2">{`/ ${10}`}</Typography>
								</ListItemSecondaryAction>
							</ListItem>

							<ListItem>
								<ListItemText primary={"Active Devices"} />
								<ListItemSecondaryAction
									style={{ display: "flex", alignItems: "center" }}
								>
									<Typography variant="body1">50</Typography>
									<Typography variant="body2">{`/ ${75}`}</Typography>
								</ListItemSecondaryAction>
							</ListItem>
						</List>
					</Grid>

					{/* //-		Products		 */}
					<Grid
						item
						xs={12}
						sm={6}
						style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
					>
						<List>
							<ListItem
								secondaryAction={
									<Button aria-label="add product" size="small">
										Add Product
									</Button>
								}
							>
								<ListItemText primary={"Products"} />
							</ListItem>

							<Divider />

							{organization?.products?.map((product: any) => (
								<ListItem key={product?.id} dense>
									<ListItemText
										primary={product?.name}
										secondary={
											<Button
												color="error"
												size="small"
												disabled={product.enabled}
												component="button"
												onClick={() => handleRemoveProduct(product.id)}
											>
												Remove
											</Button>
										}
									/>
									<ListItemSecondaryAction>
										<Switch
											value={product.enabled}
											checked={product.enabled}
											onChange={() => handleUpdateProducts(product.id)}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							))}

							<Divider />

							{differenceBy(productValues, organization?.products, "id")?.map(
								(product: any) => (
									<ListItem key={product?.id}>
										<ListItemText primary={product?.name} />
										<ListItemSecondaryAction>
											<IconButton
												size="small"
												color="primary"
												onClick={() => handleAddProduct(product)}
											>
												<Add />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								)
							)}
						</List>
					</Grid>
				</Grid>
			</Section>

			{/* //2        Clients Table */}
			<Title
				dense
				title="Clients"
				size="small"
				button={<AddClientDialog id={organizationId} />}
			/>
			<OrganizationClientsTable
				id={organizationId}
				clients={organization?.clients || []}
			/>
		</div>
	);
};
