import * as React from "react";

import {
	Box,
	Typography,
	createStyles,
	makeStyles,
	Theme,
	LinearProgress,
} from "@material-ui/core";

// Components
import { ClientsTable } from "../pages/Clients/ClientsTable";
import { Button } from "@mui/material";
import { Switch, useHistory } from "react-router";
import PrivateRoute from "./components/PrivateRoute";
import { AddClientPage } from "../pages/Clients/AddClientPage";
import { ClientPage } from "../pages/Clients/ClientPage";

import { useAppSelector } from "../app/hooks";
import { useLoading } from "../hooks/useLoading";
import { ClientSitePage } from "../pages/Clients/ClientSitePage";
import { Title } from "../components/Title/Title";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		header: {
			display: "flex",
			alignItems: "center",
			marginBottom: "1rem",
		},
		grow: {
			display: "flex",
			flexGrow: 1,
		},
	})
);

interface IClientsRouterProps {}

export const ClientsRouter: React.FC<IClientsRouterProps> = (props) => {
	const classes = useStyles();
	const history = useHistory();

	const isLoading = useLoading("clients");

	// Handlers
	const handleClick = () => {
		history.push("/dashboard/clients/create");
	};

	// Handle loading
	if (isLoading) return <LinearProgress />;

	return (
		<Switch>
			<PrivateRoute exact path="/dashboard/clients">
				<Title
					title="All Clients"
					size="small"
					button={
						<div style={{ display: "flex", justifyContent: "end" }}>
							<Button onClick={handleClick}>Add Client</Button>
						</div>
					}
				/>

				<div>
					<ClientsTable />
				</div>
			</PrivateRoute>

			<PrivateRoute exact path="/dashboard/clients/create">
				<div>
					<AddClientPage />
				</div>
			</PrivateRoute>

			<PrivateRoute exact path="/dashboard/clients/:clientId/site/:siteId">
				<div>
					<ClientSitePage />
				</div>
			</PrivateRoute>

			{/* //!		Route that uses param must be at the end */}
			<PrivateRoute exact path="/dashboard/clients/:clientId">
				<div>
					<ClientPage />
				</div>
			</PrivateRoute>
		</Switch>
	);
};
