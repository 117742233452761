import React, { useState } from "react";

import { orderBy } from "lodash";
import { useAppSelector } from "../app/hooks";

import { RootState } from "../app/store";

// Get list of buildings across clients
const generateSiteList: any = (clientStore: any) => {
	let siteArray: any = [];
	Object.values(clientStore).forEach((client: any) => {
		client?.sites?.forEach((site: any) => {
			siteArray.push({
				...site,
				clientId: client.id,
				clientName: client.name,
			});
		});
	});

	return orderBy(siteArray, ["name"], ["asc"]);
};

const useSiteList = () => {
	const [siteList, setSiteList]: any = useState([]);

	const clientStore = useAppSelector((state: RootState) => state.clients.data);

	React.useEffect(() => {
		setSiteList(generateSiteList(clientStore));
	}, [Object.values(clientStore).length]);

	return siteList;
};

export default useSiteList;
