import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { functions } from "../../firebase/firebase";

const getWeather = functions.httpsCallable("getWeather");

export const fetchWeather: any = createAsyncThunk(
	"weather/fetchWeather",
	async ({ address, id }: { address: string; id?: string }) => {
		return getWeather({ address }).then((res: any) => {
			// If data, set and run again if given address changes
			if (res?.data?.properties) {
				return { id, weather: res.data.properties.periods };
			}
		});
	}
);

export const weatherSlice = createSlice({
	name: "weather",
	initialState: {
		data: {},
		status: "",
		error: "",
	},
	reducers: {},
	extraReducers: {
		[fetchWeather.pending]: (state, action) => {
			state.status = "loading";
		},
		[fetchWeather.fulfilled]: (state: any, action: any) => {
			state.status = "succeeded";
			if (action.payload?.id) {
				state.data[action.payload.id] = action.payload.weather;
			}
		},
		[fetchWeather.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
	},
});

export default weatherSlice.reducer;
