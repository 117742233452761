import * as React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core";

import {
	Skeleton,
	FormControl,
	Grid,
	TextField,
	Typography,
} from "@mui/material";

import { Section } from "../../components/Section/Section";
import { Title } from "../../components/Title/Title";

interface IWhitelabelCreateProps {}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		wrapper: {
			padding: "1rem",
			borderRadius: 4,
			border: "1px solid rgba(224, 224, 224, 1)",
			marginBottom: "1rem",
		},
		header: {
			display: "flex",
			alignItems: "center",
			marginBottom: "1rem",
		},
		grow: {
			display: "flex",
			flexGrow: 1,
		},
		desktopFieldPadding: {
			[theme.breakpoints.up("sm")]: {
				paddingRight: "1rem",
			},
		},
		input: {
			margin: "1rem 0",
		},
	})
);

export const WhitelabelCreate: React.FC<IWhitelabelCreateProps> = (props) => {
	const classes = useStyles();

	const defaultNewClient = {
		id: "",
		name: "",
		tagline: "",
		description: "",
		primary: "",
		secondary: "",
		accent: "",
	};

	const [newClientData, setNewClientData]: any =
		React.useState(defaultNewClient);

	interface INewClientData {
		id: string;
		name: string;
		tagline: string;
		description: string;
		primary: string;
		secondary: string;
		accent: string;
	}

	const handleChange =
		(prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setNewClientData({ ...newClientData, [prop]: event.target.value });
		};

	return (
		<div>
			<Title title="Brand Information" size="small" dense />
			<Section>
				<Grid container>
					{/* //3     Inputs */}
					<Grid item xs={12} sm={6} className={classes.desktopFieldPadding}>
						<Typography
							variant="overline"
							style={{ display: "flex", width: "100%" }}
						>
							Text
						</Typography>

						<FormControl fullWidth>
							<TextField
								margin="none"
								size="small"
								variant="outlined"
								className={classes.input}
								fullWidth
								value={newClientData.name}
								onChange={handleChange("name")}
								label="Brand Name"
							/>
						</FormControl>

						<FormControl fullWidth>
							<TextField
								margin="none"
								size="small"
								variant="outlined"
								className={classes.input}
								fullWidth
								value={newClientData.tagline}
								onChange={handleChange("tagline")}
								label="Tagline (optional)"
							/>
						</FormControl>

						<FormControl fullWidth>
							<TextField
								margin="none"
								size="small"
								variant="outlined"
								className={classes.input}
								fullWidth
								value={newClientData.description}
								onChange={handleChange("description")}
								label="Description (optional)"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Typography
							variant="overline"
							style={{ display: "flex", width: "100%" }}
						>
							Colors
						</Typography>

						<FormControl fullWidth>
							<TextField
								margin="none"
								size="small"
								variant="outlined"
								className={classes.input}
								fullWidth
								value={newClientData.primary}
								onChange={handleChange("name")}
								label="Primary"
							/>
						</FormControl>

						<FormControl fullWidth>
							<TextField
								margin="none"
								size="small"
								variant="outlined"
								className={classes.input}
								fullWidth
								value={newClientData.secondary}
								onChange={handleChange("secondary")}
								label="Secondary"
							/>
						</FormControl>

						<FormControl fullWidth>
							<TextField
								margin="none"
								size="small"
								variant="outlined"
								className={classes.input}
								fullWidth
								value={newClientData.accent}
								onChange={handleChange("accent")}
								label="Accent"
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Section>

			<Title title="Images" size="small" dense />
			<Section>
				<Grid container>
					<Grid item sm={6} xs={12}>
						<div>
							<Typography variant="overline">Logo</Typography>
							<Skeleton height={100} />
						</div>
					</Grid>
					<Grid item sm={6} xs={12} style={{ paddingLeft: "1rem" }}>
						<Typography variant="overline">Background</Typography>
						<Skeleton height={100} />
					</Grid>
				</Grid>
			</Section>
		</div>
	);
};
