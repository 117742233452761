import * as React from "react";

import { styled, useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import {
	Menu as MenuIcon,
	ChevronLeft,
	ChevronRight,
	VerifiedUser,
	Layers,
	BusinessCenter,
	BorderOuter,
	Label,
	Search,
	SignalWifi1Bar,
	Usb,
} from "@material-ui/icons";

import { useHistory, useRouteMatch } from "react-router";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
	open?: boolean;
}>(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	transition: theme.transitions.create("margin", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginLeft: `-${drawerWidth}px`,
	...(open && {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	transition: theme.transitions.create(["margin", "width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: "flex-end",
}));

const adminMenuItems = [
	{
		title: "Organizations",
		route: "organizations",
		icon: <BusinessCenter />,
		disabled: false,
	},
	{ title: "Clients", route: "clients", icon: <Layers />, disabled: false },
	{ title: "Permissions", route: "permissions", icon: <VerifiedUser /> },
	{
		title: "Locations",
		route: "locations",
		icon: <BorderOuter />,
		disabled: true,
	},
	{ title: "Add Device", route: "devices", icon: <Usb />, disabled: true },
	{
		title: "Whitelabels",
		route: "whitelabels",
		icon: <Label />,
		disabled: false,
	},
];

const monitorMenuItems = [
	{
		title: "Monitor Devices",
		route: "monitor",
		icon: <SignalWifi1Bar />,
		disabled: false,
	},
	{
		title: "Search Devices",
		route: "searchdevices",
		icon: <Search />,
		disabled: true,
	},
];

export default function DashboardDrawer({ children }: any) {
	const theme = useTheme();
	const history = useHistory();

	// !    Required for 'selected' styling to update
	// eslint-disable-next-line
	const routeMatch = useRouteMatch();

	const [open, setOpen] = React.useState(true);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleRoute = (route: string) => {
		history.push(`/dashboard/${route}`);
	};

	const splitPath = history.location.pathname.split("/");
	const historyState: any = history.location.state;

	const title = historyState?.title
		? historyState.title
		: splitPath[splitPath.length - 1].toUpperCase();

	return (
		<Box sx={{ display: "flex", width: "100%" }}>
			{/* //.  AppBar */}
			<AppBar position="fixed" open={open}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{ mr: 2, ...(open && { display: "none" }) }}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap component="div">
						{title}
					</Typography>
				</Toolbar>
			</AppBar>

			{/* //.  Drawer */}
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
					},
				}}
				variant="persistent"
				anchor="left"
				open={open}
			>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
					</IconButton>
				</DrawerHeader>
				<Divider />

				{/* //1     Admin menu items */}
				<List>
					<ListItem>
						<ListItemText primary={"Admin"} />
					</ListItem>

					{adminMenuItems.map((item) => (
						<ListItem
							button
							key={item.route}
							onClick={() => handleRoute(item.route)}
							selected={Boolean(history.location.pathname.includes(item.route))}
							disabled={item.disabled}
						>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText primary={item.title} />
						</ListItem>
					))}
				</List>

				<Divider />

				{/* //2     Monitor menu items */}
				<List>
					<ListItem>
						<ListItemText primary={"Monitor"} />
					</ListItem>

					{monitorMenuItems.map((item) => (
						<ListItem
							button
							key={item.route}
							onClick={() => handleRoute(item.route)}
							selected={Boolean(history.location.pathname.includes(item.route))}
							disabled={item.disabled}
						>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText primary={item.title} />
						</ListItem>
					))}
				</List>
			</Drawer>

			<Main open={open}>
				<DrawerHeader />
				{children}
			</Main>
		</Box>
	);
}
