import * as React from "react";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

import { ClientsTable } from "../Clients/ClientsTable";
import { WhitelabelsTable } from "./WhitelabelsTable";

interface IWhitelabelsProps {}

export const Whitelabels: React.FC<IWhitelabelsProps> = (props) => {
	const whitelabels = useAppSelector(
		(state: RootState) => state.firestore.ordered?.whitelabels
	);
	return (
		<div>
			<WhitelabelsTable />
		</div>
	);
};
