import React, { useState } from "react";

// Components
import IntroLoader from "./components/IntroLoader/IntroLoader";
import Login from "./pages/Login/Login";

// MUI
import {
	Button,
	createStyles,
	makeStyles,
	MuiThemeProvider,
	Theme,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { indigo } from "@material-ui/core/colors";

// Hooks
import { useAppSelector } from "./app/hooks";

// Redux
import { RootState, store } from "./app/store";
import { fetchClients } from "./features/clients/clientsSlice";

// Firebase
import { useFirebase } from "react-redux-firebase";

// Router
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./routes/components/PrivateRoute";

// Pages
import { Dashboard } from "./pages/Dashboard";
import { Monitor } from "./pages/Monitor/Monitor";

// Providers
import { SnackbarProvider } from "notistack";

import { setAutoFreeze } from "immer";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		app: {
			width: "100%",
			backgroundSize: "cover",
			backgroundPosition: "center",
			backgroundAttachment: "fixed",
		},
	})
);

function App() {
	setAutoFreeze(false);
	// DB
	const firebaseHook = useFirebase();

	// Styles
	const classes = useStyles();
	// const size = useWindowSize();

	// Store
	const auth = useAppSelector((state: RootState) => state.firebase.auth);
	const clients = useAppSelector((state: RootState) => state.clients);
	const profile = useAppSelector((state: RootState) => state.firebase.profile);

	// Action dispatch
	const dispatch = store.dispatch;

	// State
	const [error, setError] = useState({ title: "", message: "" });
	const [whitelabel, setWhitelabel] = React.useState({
		enabled: false,
		logoURL: "",
		primary: "",
		secondary: "",
		padding: "",
	});

	const { primary, secondary } = whitelabel;

	// Handles initial authentication
	// Convenience
	const init = Boolean(auth.isLoaded && profile.isLoaded);

	React.useEffect(() => {
		// If auth has been initialized, an API key is present, and a client fetch has yet to be attempted
		if (init && profile?.api && clients?.status === "") {
			dispatch(fetchClients({ api: profile.api, type: "private" }));
		}

		// Handle failure to fetch
		if (clients?.status === "failed") {
			setError({
				title: "Clients fetch failed",
				message: `An ${clients?.error} error that caused a failure to fetch your clients. Please retry.`,
			});
		}

		// Remove error if fetch succeeds
		if (clients?.status === "succeeded") {
			setError({ title: "", message: "" });
		}

		// no need to refresh on clients, dispatch, or profile
		// eslint-disable-next-line
	}, [init, profile.api, clients?.status]);

	// Clients fetch retry handler
	const handleRetry = () => {
		setError({ title: "", message: "" });
		dispatch(fetchClients({ api: profile.api, type: "private" }));
	};

	// Loader handles both uninitialized and error states
	if (!init || error.title) {
		return (
			<IntroLoader
				title={"Welcome"}
				trigger={!init}
				error={error}
				retry={
					error && (
						<Button onClick={() => handleRetry()} variant="outlined">
							Reconnect
						</Button>
					)
				}
			/>
		);
	}

	// Construct theme
	let theme: Theme = createTheme();

	// If whitelabel is enabled, proceed
	if (whitelabel.enabled) {
		// Set up default theme
		let palette: any = {
			primary: { main: indigo[500] },
			secondary: { main: indigo[500] },
		};

		// Adjust primary and secondary colors
		if (primary) {
			palette.primary.main = primary;
		}

		if (secondary) {
			palette.secondary.main = secondary;
		}

		// Output new palette
		theme = createTheme({ palette });
	}

	// Render router
	return (
		<MuiThemeProvider theme={theme}>
			<div className={classes.app}>
				<SnackbarProvider maxSnack={3}>
					<BrowserRouter>
						<Switch>
							{/* Redirect from root to /dashboard, if not authenticated will automatically redirect to /login */}
							<Redirect exact from="/" to="/dashboard" />
							<Redirect exact from="/home" to="/dashboard" />

							<Route path="/login">
								<Login />
							</Route>

							{/* //1 Contains all /dashboard/* subroutes */}
							<PrivateRoute path="/dashboard">
								<Dashboard />
							</PrivateRoute>
						</Switch>
					</BrowserRouter>
				</SnackbarProvider>
			</div>
		</MuiThemeProvider>
	);
}

export default App;
