import * as React from "react";

// MUI
import {
	Box,
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from "@mui/material";

// Icons
import { FilterList } from "@material-ui/icons";

// Util
import { filter, find, uniqBy } from "lodash";

interface ISearchBar {
	title?: string;
	devices: any;
	isLoading?: boolean;
	filterBy: {
		building: any;
		floor: any;
		space: any;
		type: any;
	};
	setFilterBy: any;
}

export default function DeviceFilters({
	title,
	devices,
	isLoading = false,
	filterBy,
	setFilterBy,
}: ISearchBar) {
	const defaultFilters = {
		building: [],
		floor: [],
		space: [],
		type: [],
	};

	// Change handler
	const handleChange = (event: SelectChangeEvent) => {
		const {
			target: { name, value },
		} = event;

		if (name === "building") {
			setFilterBy({
				...defaultFilters,
				[name]: typeof value === "string" ? value.split(",") : value,
			});
		} else if (name === "floor") {
			setFilterBy({
				...filterBy,
				space: [],
				[name]: typeof value === "string" ? value.split(",") : value,
			});
		} else {
			setFilterBy({
				...filterBy,
				[name]: typeof value === "string" ? value.split(",") : value,
			});
		}
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Typography
				variant="h6"
				noWrap
				component="div"
				sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
			>
				{title}
			</Typography>

			{/* Selects */}
			<Box
				sx={{
					minWidth: 120,
					paddingBottom: "1rem",
					display: "flex",
				}}
			>
				{/* Filter by buildings */}
				<FormControl fullWidth margin="dense">
					<InputLabel id="demo-simple-select-label">Buildings</InputLabel>
					<Select
						name="building"
						multiple
						value={filterBy.building}
						renderValue={(selected: any) => {
							let selects: any = [];
							selected.forEach((select: any) => {
								selects.push(
									find(devices, ["buildingId", select])?.buildingName
								);
							});
							return selects.join(", ");
						}}
						label="Buildings"
						onChange={handleChange}
						IconComponent={FilterList}
						style={{ borderRadius: 0 }}
					>
						{uniqBy(devices, (device: any) => device.buildingId)?.map(
							(device: any) => (
								<MenuItem key={device.id} value={device.buildingId}>
									<Checkbox
										checked={filterBy.building.indexOf(device.buildingId) > -1}
									/>
									<ListItemText primary={device.buildingName} />
								</MenuItem>
							)
						)}
					</Select>
				</FormControl>

				{/* Filter by floors */}
				<FormControl fullWidth margin="dense">
					<InputLabel id="demo-simple-select-label">Floors</InputLabel>
					<Select
						name="floor"
						multiple
						value={filterBy.floor}
						renderValue={(selected: any) => {
							let selects: any = [];
							selected.forEach((select: any) => {
								selects.push(find(devices, ["floorId", select])?.floorName);
							});
							return selects.join(", ");
						}}
						label="Floors"
						onChange={handleChange}
						IconComponent={FilterList}
						disabled={!Boolean(filterBy.building.length)}
						style={{ borderRadius: 0 }}
					>
						{uniqBy(
							filter(devices, (device: any) =>
								filterBy.building.includes(device.buildingId)
							),
							(device: any) => device.floorId
						)?.map((device: any) => (
							<MenuItem key={device.id} value={device.floorId}>
								<Checkbox
									checked={filterBy.floor.indexOf(device.floorId) > -1}
								/>
								<ListItemText
									primary={device.floorName}
									secondary={`${device.buildingName}`}
								/>
							</MenuItem>
						))}
					</Select>
				</FormControl>

				{/* Filter by spaces */}
				<FormControl fullWidth margin="dense">
					<InputLabel id="demo-simple-select-label">Spaces</InputLabel>
					<Select
						name="space"
						multiple
						value={filterBy.space}
						renderValue={(selected: any) => {
							let selects: any = [];
							selected.forEach((select: any) => {
								selects.push(find(devices, ["spaceId", select])?.spaceName);
							});
							return selects.join(", ");
						}}
						label="Spaces"
						onChange={handleChange}
						IconComponent={FilterList}
						disabled={!Boolean(filterBy.space.length || filterBy.floor.length)}
						style={{ borderRadius: 0 }}
					>
						{uniqBy(
							filter(devices, (device: any) =>
								filterBy.floor.includes(device.floorId)
							),
							(device: any) => device.spaceId
						)?.map((device: any) => (
							<MenuItem key={device.id} value={device.spaceId}>
								<Checkbox
									checked={filterBy.space.indexOf(device.spaceId) > -1}
								/>
								<ListItemText
									primary={device.spaceName}
									secondary={`${device.buildingName} > ${device.floorName}`}
								/>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		</Box>
	);
}
