import * as React from "react";
// Mui
import {
	DataGrid,
	GridCallbackDetails,
	GridColDef,
	GridSelectionModel,
	GridState,
} from "@mui/x-data-grid";
import { Typography } from "@mui/material";

// Icons
import { Brightness1 } from "@material-ui/icons";

// Colors
import { blue, green, red } from "@material-ui/core/colors";

// Redux
import { useAppSelector } from "../../app/hooks";

const statusStyles = {
	root: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	icon: {
		fontSize: ".5rem",
		marginRight: "1rem",
	},
	idle: {
		color: blue[500],
	},
	success: {
		color: green[500],
	},
	failed: {
		color: red[500],
	},
};

const columns: GridColDef[] = [
	{ field: "name", headerName: "Device Name", width: 200 },
	{ field: "virtualProfile", headerName: "Device Type", width: 130 },
	{ field: "buildingName", headerName: "Building", width: 100 },
	{
		headerName: "Floor",
		field: "floorName",
		width: 100,
	},
	{
		headerName: "Space",
		field: "spaceName",
		width: 130,
	},
	{
		headerName: "Fetch Status",
		field: "points",
		headerAlign: "center",
		width: 160,
		renderCell: (params: any) => {
			return (
				<div style={statusStyles.root}>
					{!params?.row?.wasFetched ? (
						<div style={statusStyles.root}>
							<Brightness1
								style={{ ...statusStyles.icon, ...statusStyles.idle }}
							/>
							<Typography variant="subtitle2">Idle</Typography>
						</div>
					) : params?.row?.wasFetched ? (
						<div style={statusStyles.root}>
							<Brightness1
								style={{ ...statusStyles.icon, ...statusStyles.success }}
							/>
							<Typography variant="subtitle2">Success</Typography>
						</div>
					) : (
						""
					)}
				</div>
			);
		},
	},
	{
		headerName: "Data",
		field: "data",
		headerAlign: "center",
		width: 160,
		renderCell: (params: any) => {
			return params?.row?.wasFetched ? (
				<div style={statusStyles.root}>
					{!params?.row?.points?.length ? (
						<div style={statusStyles.root}>
							<Brightness1
								style={{ ...statusStyles.icon, ...statusStyles.failed }}
							/>
							<Typography variant="subtitle2">No Data</Typography>
						</div>
					) : params?.row?.wasFetched ? (
						<div style={statusStyles.root}>
							<Brightness1
								style={{ ...statusStyles.icon, ...statusStyles.success }}
							/>
							<Typography variant="subtitle2">Success</Typography>
						</div>
					) : (
						""
					)}
				</div>
			) : (
				<div />
			);
		},
	},
];

interface IDeviceDataGrid {
	devices: any[];
	selectionModel: GridSelectionModel;

	handleSelection: (
		selectionModel: GridSelectionModel,
		details: GridCallbackDetails
	) => void;
	handleStateChange: (state: GridState) => void;
}

export default function DeviceDataGrid({
	devices,
	selectionModel,
	handleSelection,
	handleStateChange,
}: IDeviceDataGrid) {
	const deviceData = useAppSelector((state: any) => state.devices?.data);

	const getDeviceStatus = () => {
		return devices.map((device: any) => {
			let deviceFromRedux = deviceData?.[device?.id];
			let devicePayload = { ...device, wasFetched: false };

			if (deviceFromRedux) {
				return {
					...devicePayload,
					...deviceFromRedux,
					wasFetched: true,
				};
			} else return devicePayload;
		});
	};

	let mergedDeviceData = getDeviceStatus();

	return (
		<div style={{ height: 350, width: "100%" }}>
			<DataGrid
				rows={mergedDeviceData}
				columns={columns}
				pageSize={50}
				rowsPerPageOptions={[50]}
				checkboxSelection
				selectionModel={selectionModel}
				onSelectionModelChange={handleSelection}
				onStateChange={handleStateChange}
			/>
		</div>
	);
}
