import * as React from "react";

// MUI
import { Button, LinearProgress } from "@mui/material";

// Icons
import { PersonAdd, PersonRemove } from "@mui/icons-material";

// Components
import { Title } from "../../components/Title/Title";

// Routing
import { useParams } from "react-router";
import { PermissionsDrawer } from "./PermissionsDrawer";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

interface IOrganizationPermissionsProps {}

export const OrganizationPermissions: React.FC<
	IOrganizationPermissionsProps
> = (props) => {
	const params: { id: string } = useParams();
	const organizationId = params?.id;

	// Drawer
	const [openDrawer, setOpenDrawer] = React.useState(true);
	const handleClose = () => {
		setOpenDrawer(false);
	};

	const organizations = useAppSelector(
		(state: RootState) => state.firestore.data?.organizations
	);
	const thisOrganization = organizations?.[organizationId];

	if (!organizations) return <LinearProgress style={{ width: "100%" }} />;

	console.log(thisOrganization);

	return (
		<div>
			{/* //.         Header       */}
			<Title
				title="Organization Permissions"
				button={
					<div>
						<Button
							style={{ marginRight: "1rem" }}
							onClick={() => setOpenDrawer(true)}
						>
							<PersonAdd style={{ marginRight: 5 }} /> Add
						</Button>
						<Button disabled>
							<PersonRemove style={{ marginRight: 5 }} /> Remove
						</Button>
					</div>
				}
			/>

			{/* Drawer */}
			<PermissionsDrawer open={openDrawer} handleClose={handleClose} />
		</div>
	);
};
