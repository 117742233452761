import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { functions } from "../../firebase/firebase";

const fetchEndpoint = functions.httpsCallable("fetchEndpoint");
const fetchEndpointPublic = functions.httpsCallable("fetchEndpointPublic");

export const fetchClients: any = createAsyncThunk(
	"clients/fetchClients",
	async ({ api, type }: { api?: string; type: "public" | "private" }) => {
		let response;
		if (type === "public") {
			response = await fetchEndpointPublic({ route: "/clients" });
		} else {
			response = await fetchEndpoint({ route: "/clients", api });
		}
		const parsedResponse = JSON.parse(response.data);

		if (Array.isArray(parsedResponse) && parsedResponse.length) {
			const callback = (acc: any, cv: any) => {
				acc[cv.client.id] = cv.client;
				return acc;
			};
			return parsedResponse.reduce(callback, {});
		} else {
			return {};
		}
	}
);

export const clientsSlice = createSlice({
	name: "clients",
	initialState: {
		data: {},
		status: "",
		error: { message: "", id: "" },
	},
	reducers: {},
	extraReducers: {
		[fetchClients.pending]: (state, action) => {
			state.status = "loading";
		},
		[fetchClients.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.data = action.payload;
		},
		[fetchClients.rejected]: (state, action) => {
			state.status = "failed";
			state.error = { message: action.error.message, id: action.meta?.arg?.id };
		},
	},
});

export default clientsSlice.reducer;
