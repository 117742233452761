import React from "react";

// Firebase
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

// Util
import { isEmpty } from "lodash";

const useGetLicenses = () => {
	const [licenses, setLicenses]: any = React.useState([]);

	useFirestoreConnect(["licenses"]);
	let firestoreLicenses: any =
		useSelector((state: any) => state.firestore.data?.licenses) || {};

	React.useEffect(() => {
		if (firestoreLicenses && !isEmpty(firestoreLicenses)) {
			let licenseValues = [];
			licenseValues = Object.keys(firestoreLicenses).map((license: any) => ({
				...firestoreLicenses[license],
				id: license,
			}));
			setLicenses(licenseValues);
		}
	}, [firestoreLicenses]);

	return licenses;
};

export default useGetLicenses;
