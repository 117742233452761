import * as React from "react";

import { Typography } from "@material-ui/core";

interface ITitleProps {
	title: string;
	button?: JSX.Element;
	size?: "small" | "default" | "large";
	dense?: boolean;
}

export const Title: React.FC<ITitleProps> = ({
	title,
	button,
	size = "default",
	dense = false,
}: ITitleProps) => {
	let variant =
		size === "small"
			? { fontWeight: 300, fontSize: "1rem" }
			: size === "large"
			? { fontSize: "1.8rem" }
			: {};

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				marginBottom: dense ? 0 : "1rem",
			}}
		>
			<Typography
				variant="h6"
				style={{
					...variant,
					display: "flex",
					minHeight: 36.5,
					alignItems: "center",
					flex: "1 1 0",
				}}
			>
				{title}
			</Typography>

			{button || <span />}
		</div>
	);
};
