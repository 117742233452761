import * as React from "react";

import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import { Paper } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			padding: (props: any) => (props.disablePadding ? 0 : "1rem"),
			borderRadius: 4,
			border: "1px solid rgba(224, 224, 224, 1)",
			marginBottom: "1rem",
		},
	})
);

interface ISectionProps {
	disablePadding?: boolean;
	variant?: "gray" | "grey";
	style?: {};
}

export const Section: React.FC<ISectionProps> = ({
	children,
	disablePadding = false,
	variant = "",
	style = {},
}) => {
	const classes: any = useStyles({ disablePadding });
	const theme = useTheme();

	return (
		<Paper
			elevation={0}
			className={classes.wrapper}
			style={{
				backgroundColor: !variant ? theme.palette.background.paper : "inherit",
				...style,
			}}
		>
			{children}
		</Paper>
	);
};
