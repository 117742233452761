import * as React from "react";

// MUI
import {
	DataGrid,
	GridCallbackDetails,
	GridColDef,
	GridRowParams,
	GridValueGetterParams,
	MuiEvent,
} from "@mui/x-data-grid";

// Redux
import { useSelector } from "react-redux";
import { IClient } from "../../interfaces";
import ClientsInfoDialog from "./ClientsInfoDialog";
import { useHistory } from "react-router";

interface IClientsTableProps {}

export const ClientsTable: React.FC<IClientsTableProps> = (props) => {
	const history = useHistory();

	// Data
	const clients = useSelector((state: any) => state.clients?.data);
	const clientsList = Object.values(clients);

	// Handlers
	const [infoData, setInfoData] = React.useState({});

	const handleClose = () => {
		setInfoData({});
	};

	// Table identity
	const columns: GridColDef[] = [
		{ field: "id", headerName: "ID", width: 150 },
		{ field: "name", headerName: "Client Name", width: 200 },
		{ field: "contact", headerName: "Contact", width: 130 },
		{
			field: "email",
			headerName: "Email",
			width: 200,
		},
		{
			field: "sites",
			headerName: "Sites",
			description: "Current list of client sites.",
			sortable: false,
			valueGetter: (params: GridValueGetterParams) =>
				`${params.row.sites?.length || ""} `,
		},
	];

	const rows: any = clientsList;

	const onRowClick = (params: GridRowParams) => {
		history.push(`/dashboard/clients/${params.row.id}`, {
			title: params.row.name,
		});
	};

	return (
		<div style={{ height: 400, width: "100%" }}>
			{Boolean(clientsList.length) && (
				<DataGrid
					rows={rows}
					columns={columns}
					pageSize={5}
					rowsPerPageOptions={[5]}
					onRowClick={onRowClick}
					isCellEditable={() => false}
					disableSelectionOnClick={true}
				/>
			)}

			{/* //! Info dialog */}

			<ClientsInfoDialog infoData={infoData} handleClose={handleClose} />
		</div>
	);
};
