import * as React from "react";

// Mui
import {
	Button,
	Dialog,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Slide,
	Grid,
} from "@mui/material";

import { Close } from "@material-ui/icons";
import { TransitionProps } from "@mui/material/transitions";

// Util
import { find, isEmpty } from "lodash";
import { IDevice } from "../../interfaces";
import MomentUtils from "@date-io/moment";

// Redux
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { fetchDevice } from "../../features/devices/devicesSlice";

// Hooks
import useDeviceList from "../../hooks/useDeviceList";
import { useSnackbar } from "notistack";

// Components
import { IAQCard } from "../../components/DeviceWidgets/IAQ";
import { Section } from "../../components/Section/Section";
import { Title } from "../../components/Title/Title";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

// Firebase
import { functions } from "../../firebase/firebase";
import { fetchHistory } from "../../features/history/historySlice";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="left" ref={ref} {...props} />;
});

interface IMonitorReportDialogProps {
	isLoading?: boolean;
	devices?: any;
	selectionModel?: any;
}

export const MonitorReportDialog: React.FC<IMonitorReportDialogProps> = ({
	isLoading,
	devices,
	selectionModel,
}) => {
	const [open, setOpen] = React.useState(false);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	// Device Data
	const api = useAppSelector((state: any) => state.firebase.profile.api);
	const deviceData = useAppSelector((state: any) => state.devices?.data);
	const historyData = useAppSelector((state: any) => state.history?.data);

	const deviceList = useDeviceList();

	const devicePointData =
		Object.values(deviceData).filter((deviceData: any) =>
			selectionModel.includes(deviceData?.id)
		) || [];

	// History
	const [loadingData, setLoadingData] = React.useState(false);
	const [deviceReportData, setDeviceReportData]: any = React.useState([]);

	// History Dates
	const [date, setDate] = React.useState({
		start: new Date(),
		end: new Date(),
	});
	const handleChangeDate = (data: any, range: any) => {
		setDate({ ...date, [range]: data.toDate() });
	};

	// . 			HISTORY FETCH HANDLER							//
	const handleFetchHistory = (id: string, name: string) => {
		if (id && name) {
			dispatch(
				fetchHistory({
					api,
					id,
					name,
					start: date.start,
					end: date.end,
				})
			);
		}
	};

	const handleGenerateReport = () => {
		devicePointData.forEach((device: any) => {
			device.points?.forEach((point: any) => {
				handleFetchHistory(point?.id, point?.name);
			});
		});
	};

	console.log(historyData);

	const handleClose = () => {
		setOpen(false);
	};

	React.useEffect(() => {
		let devicesData: any = [];
		let thisDevice = {};
		selectionModel?.forEach((id: string) => {
			thisDevice = find(deviceList, ["id", id]);
			if (!isEmpty(thisDevice)) {
				devicesData.push(thisDevice);
			}
		});

		setDeviceReportData(devicesData);
	}, [selectionModel?.length]);

	React.useEffect(() => {
		if (open) {
			deviceReportData.forEach((device: IDevice) => {
				if (!deviceData?.[device?.id]) {
					dispatch(
						fetchDevice({
							api,
							id: device.id,
							buildingId: device.buildingId,
							floorId: device.floorId,
							spaceId: device.spaceId,
						})
					);
				}
			});
		}
	}, [selectionModel?.length, open]);

	return (
		<>
			{/* //1 Button */}
			<Button
				disabled={!Boolean(selectionModel?.length)}
				variant="contained"
				onClick={() => setOpen(true)}
				color="success"
			>
				Generate Report
			</Button>

			{/* //2 Dialog */}

			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: "relative" }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<Close />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Generate Report
						</Typography>
					</Toolbar>
				</AppBar>

				<Section>
					<Title
						title="Select dates to report device history"
						button={
							<Button onClick={handleGenerateReport}>Create Report</Button>
						}
					/>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<>
							<DatePicker
								autoOk
								disableFuture
								inputVariant="outlined"
								label="Start Date"
								openTo="date"
								value={date.start}
								onChange={(date: any) => handleChangeDate(date, "start")}
							/>
						</>
					</MuiPickersUtilsProvider>

					{/* End Date */}
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<>
							<DatePicker
								disableFuture
								showTodayButton
								inputVariant="outlined"
								label="End Date"
								openTo="date"
								minDate={date.start}
								value={date.end}
								onChange={(date: any) => handleChangeDate(date, "end")}
							/>
						</>
					</MuiPickersUtilsProvider>
				</Section>

				<Grid container>
					{devicePointData.map((device: any) => {
						return device.virtualProfile === "virtualAirQuality" ? (
							<Grid key={device?.id} item xs={12} sm={6}>
								<IAQCard iaqDevice={device} />
							</Grid>
						) : null;
					})}
				</Grid>
			</Dialog>
		</>
	);
};
