import * as React from "react";

// Redux
import { useAppSelector } from "../../../../app/hooks";

// Interfaces
import { IPoint } from "../../../../interfaces";
import { RootState } from "../../../../app/store";

// MUI
import {
	Divider,
	Grid,
	List,
	Typography,
	Theme,
	makeStyles,
	createStyles,
} from "@material-ui/core";

// Colors
import { yellow } from "@material-ui/core/colors";

// Icons
import { ReactComponent as PM25 } from "../../../../static/svg/pm25.svg";
import { ReactComponent as CO2 } from "../../../../static/svg/c02.svg";
import { ReactComponent as Humidity } from "../../../../static/svg/humidity.svg";
import { ReactComponent as Tvocs } from "../../../../static/svg/tvocs.svg";
import { ReactComponent as Temp } from "../../../../static/svg/climate.svg";

// Components
import { GlassDial } from "../../../GlassDial/GlassDial";
import { GlassPaper } from "../../../GlassPaper/GlassPaper";
import { CSVLink } from "react-csv";

// Util
import { find, isEmpty } from "lodash";
import { CircularProgress, IconButton } from "@mui/material";
import { Download } from "@mui/icons-material";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		favoriteButtonContainer: {
			zIndex: 99,
			position: "absolute",
			right: 0,
			top: 0,
		},
		secondary: {
			position: "absolute",
			left: 0,
			paddingLeft: "1rem",
		},
		componentWrapper: {
			display: "flex",
			paddingLeft: "1rem",
		},
		iconWrapper: {
			display: "flex",
			flexWrap: "wrap",
			alignItems: "center",
			justifyContent: "center",
		},
		displayStringWrapper: {
			display: "flex",
			alignItems: "center",
		},
		// Favorite
		favorite: {
			color: yellow[500],
			stroke: "rgba(0,0,0,.25)",
		},
	})
);

interface IIAQData {
	id: string;
	name: string;
	points: IPoint | any[];
	virtualProfile: string;
}

interface IAirQualityContainerProps {
	iaqDevice: IIAQData;
	style?: any;
}

export const IAQCard: React.FC<IAirQualityContainerProps> = ({
	iaqDevice,
	style,
}) => {
	const classes = useStyles();

	// History
	const stateHistory = useAppSelector((state: any) => state.history);

	// Redux device data
	const deviceStore = useAppSelector((state: RootState) => state.devices); //devices fetched and stored on selection
	const deviceData = deviceStore.data?.[iaqDevice.id];

	// ** CONTROL POINT: If no device points, return null. Everything below assumes deviceData !== void 0
	if (Boolean(!deviceData) || Boolean(!deviceData.points?.length)) {
		return null;
	}

	// IAQ Device points
	const score = find(deviceData.points, [
		"virtualDeviceSlot",
		"airQualityScore",
	]);

	const co2 = find(deviceData.points, ["virtualDeviceSlot", "co2"]);
	const humidity = find(deviceData.points, ["virtualDeviceSlot", "humidity"]);
	const pm25 = find(deviceData.points, ["virtualDeviceSlot", "pm25"]);
	const voc = find(deviceData.points, ["virtualDeviceSlot", "voc"]);
	const temp = find(deviceData.points, ["virtualDeviceSlot", "tempDisplay"]);

	// TODO: Description doesn't have a virtualDeviceSlot
	const description =
		find(deviceData.points, ["virtualDeviceSlot", "description"]) ||
		find(deviceData.points, ["name", "air Quality Description Control Point"]);

	// ** CONTROL POINT: If no score.id (IAQ is without it's number component, return null;)
	if (!Boolean(score?.id)) return null;

	interface IIAQListComponent {
		icon: any;
		title: string;
		point: any;
	}

	const IAQListComponent = ({ icon, title, point }: IIAQListComponent) => {
		let historyPoint = stateHistory.data?.[point.id];
		return (
			<Grid container className={classes.componentWrapper}>
				<Grid item xs={2} className={classes.iconWrapper}>
					{icon}
				</Grid>
				<Grid item xs={4}>
					<Typography variant="overline">{title}</Typography>
				</Grid>
				<Grid item xs={4} className={classes.displayStringWrapper}>
					<Typography variant="body1">
						{point?.data?.displayString || ""}
					</Typography>
				</Grid>
				<Grid item xs={2} className={classes.iconWrapper}>
					{stateHistory.status === "loading" &&
					!stateHistory.data?.[point.id] ? (
						<CircularProgress size={10} />
					) : stateHistory.data?.[point.id] ? (
						<CSVLink
							data={historyPoint?.data || []}
							filename={`${deviceData?.name || ""} ${
								historyPoint?.name || ""
							} ${historyPoint?.startTime || ""} - ${
								historyPoint?.endTime || ""
							}`}
						>
							<IconButton size="small">
								<Download />{" "}
							</IconButton>
						</CSVLink>
					) : null}
				</Grid>
			</Grid>
		);
	};

	return (
		<GlassPaper square {...style}>
			{/* Title */}
			<Typography
				variant="h6"
				style={{ width: "100%", padding: ".5rem 0" }}
				align="center"
			>
				{deviceData.name}
			</Typography>
			<Divider style={{ width: "100%" }} />

			{/* Score Dial */}
			<Grid container style={{ padding: "1rem 0" }}>
				<Grid
					item
					xs={4}
					style={{
						display: "flex",
						alignItems: "baseline",
						justifyContent: "center",
					}}
				>
					<GlassDial
						id={score.id}
						value={Math.round(Number(score.data.value))}
						label={description ? description.data.value : "IAQ"}
						size={100}
						labelFontSize={"1rem"}
						colorByValue
					/>
					{stateHistory.status === "loading" &&
					!stateHistory.data?.[score?.id] ? (
						<CircularProgress size={10} />
					) : stateHistory.data?.[score?.id] ? (
						<CSVLink
							data={stateHistory.data?.[score?.id]?.data || []}
							filename={`${deviceData?.name || ""} ${
								stateHistory.data?.[score?.id]?.name || ""
							} ${stateHistory.data?.[score?.id]?.startTime || ""} - ${
								stateHistory.data?.[score?.id]?.endTime || ""
							}`}
						>
							<IconButton size="small">
								<Download />{" "}
							</IconButton>
						</CSVLink>
					) : null}
				</Grid>

				{/* Components */}
				<Grid item xs={8}>
					<List>
						{/* CO2 */}
						{!Boolean(isEmpty(co2)) && (
							<IAQListComponent
								icon={<CO2 height="1rem" style={{ margin: "0 .5rem" }} />}
								title="CO2"
								point={co2}
							/>
						)}

						{/* Humidity */}
						{!Boolean(isEmpty(humidity)) && (
							<IAQListComponent
								icon={<Humidity height="1rem" style={{ margin: "0 .5rem" }} />}
								title="Humidity"
								point={humidity}
							/>
						)}

						{/* TVOCS */}
						{!Boolean(isEmpty(voc)) && (
							<IAQListComponent
								icon={<Tvocs height="1rem" style={{ margin: "0 .5rem" }} />}
								title="VOCs"
								point={voc}
							/>
						)}

						{/* PM25 */}
						{!Boolean(isEmpty(pm25)) && (
							<IAQListComponent
								icon={<PM25 height="1rem" style={{ margin: "0 .5rem" }} />}
								title="PM25"
								point={pm25}
							/>
						)}

						{/* Temperature */}
						{!Boolean(isEmpty(temp)) && (
							<IAQListComponent
								icon={<Temp height="1rem" style={{ margin: "0 .5rem" }} />}
								title="Temp"
								point={temp}
							/>
						)}
					</List>
				</Grid>
			</Grid>
		</GlassPaper>
	);
};
