import React, { useState } from "react";

import { orderBy } from "lodash";
import { useAppSelector } from "../app/hooks";

import { RootState } from "../app/store";
import useFetchBuildings from "./useFetchBuildings";

// Get list of buildings across clients
const generateFloorList: any = (buildingStore: any) => {
	let floorArray: any = [];
	Object.values(buildingStore).forEach((building: any) => {
		building?.floors?.forEach((floor: any) => {
			floorArray.push({
				...floor,
				buildingId: building.id,
				buildingName: building.name,
			});
		});
	});

	return orderBy(floorArray, ["buildingName", "name"], ["asc"]);
};

const useFloorList = () => {
	const [floorList, setFloorList]: any = useState([]);

	const buildingStore = useAppSelector(
		(state: RootState) => state.buildings.data
	);

	const didFetchBuildings = useFetchBuildings();

	React.useEffect(() => {
		setFloorList(generateFloorList(buildingStore));
	}, [Object.values(buildingStore).length, didFetchBuildings]);

	return floorList;
};

export default useFloorList;
