import * as React from "react";

// MUI
import {
	Box,
	Drawer,
	List,
	Divider,
	ListItem,
	ListItemText,
	Toolbar,
	Typography,
	Link,
	TextField,
	ListItemSecondaryAction,
	Fab,
	IconButton,
	MenuItem,
	Collapse,
	LinearProgress,
	LinearProgressProps,
	Button,
	Grid,
} from "@mui/material";

import { createStyles, makeStyles, Theme } from "@material-ui/core";

// Icons
import { Add, ArrowLeft } from "@mui/icons-material";

import { RootState } from "../../app/store";

// Hooks
import { useAppSelector } from "../../app/hooks";
import useBuildingList from "../../hooks/useBuildingList";
import useFloorList from "../../hooks/useFloorList";
import useSiteList from "../../hooks/useSiteList";

// Components
import { Section } from "../../components/Section/Section";
import { ResourceListItem } from "./ResourceListItem";
import { find, orderBy } from "lodash";
import { Delete } from "@material-ui/icons";
import { deepOrange } from "@mui/material/colors";

const normalize = (value: number, max: number) => (value * 100) / max;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawerPaper: {
			width: "60%",
		},
		section: {
			marginBottom: "1rem",
		},
	})
);

interface IPermissionsDrawerProps {
	handleAddCondition: any;
}

export interface IResource {
	id: string;
	data: {
		name: string;
		id: string;
		siteName?: string;
		buildingName?: string;
		floorName?: string;
	};
	type: {
		label: string;
		value: string;
	};
	permissions: string;
}

const resourceOptions = [
	{ label: "Sites", value: "site" },
	{ label: "Buildings", value: "building" },
	{ label: "Floors", value: "floor" },
	{ label: "Devices", value: "device" },
];

function LinearProgressWithLabel(
	props: LinearProgressProps & { value: number }
) {
	return (
		<Box sx={{ display: "flex", alignItems: "center" }}>
			<Box sx={{ width: "100%", mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">{`${Math.round(
					props.value
				)}%`}</Typography>
			</Box>
		</Box>
	);
}

export const ConditionsDrawer: React.FC<IPermissionsDrawerProps> = ({
	handleAddCondition,
}) => {
	const [open, setOpen] = React.useState(false);
	const handleClose = () => {
		setOpen(false);
	};

	const classes = useStyles();
	const clientStore = useAppSelector((state: RootState) => state.clients);
	const buildingStore = useAppSelector((state: RootState) => state.buildings);

	const sites = useSiteList();
	const buildings = useBuildingList();
	const floors = useFloorList();

	const defaultCondition = {
		name: "",
		description: "",
	};
	const [condition, setCondition]: any = React.useState(defaultCondition);
	const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCondition({ ...condition, [event.target.name]: event.target.value });
	};

	// .		Loading															//
	// .	In order to get floors, individual buildings must be fetched.		//
	// .	Buildings fetched are loaded into redux, below is the difference	//
	// . 	between known buildings and loaded buildings.						//
	const [fetchProgress, setFetchProgress] = React.useState(0);

	// Get IDS from a client's buildings, and building data already stored in Redux
	const buildingListIds = buildings?.map((building: any) => building.id);
	const buildingStoreIds = Object.values(buildingStore?.data).map(
		(building: any) => building?.id
	);

	React.useEffect(() => {
		if (buildingStoreIds?.length && buildingListIds?.length) {
			setFetchProgress(
				normalize(buildingStoreIds.length, buildingListIds.length)
			);
		}
	}, [buildingListIds.length, buildingStoreIds.length]);

	const defaultResourceOption = {
		label: "",
		value: "",
	};
	const [resourceOption, setResourceOption]: any = React.useState(
		defaultResourceOption
	); // Sites, Buildings, Devices, etc. First Drawer.

	const defaultResource = {
		type: { label: "", value: "" },
		data: {
			id: "",
			name: "",
		},
		id: "",
		permissions: "",
	};
	const [resource, setResource]: any = React.useState(defaultResource); // Selected resource data
	const [resources, setResources]: any = React.useState<IResource[]>([]);

	const handleChangeResourceOption = (option: {
		label: string;
		value: string;
	}) => {
		setResourceOption(option);
	};

	const handleChangeResource = (resource: IResource) => {
		setResource(resource);
	};

	const handleChangePermissions = (resource: IResource) => {
		let thisResource = find(resources, ["id", resource.id]);
		if (
			thisResource?.id &&
			thisResource?.permissions === resource.permissions
		) {
			thisResource = { ...thisResource, permissions: "" };
		} else {
			thisResource = resource;
		}

		let res = resources.filter(
			(existingRes: IResource) => existingRes.id !== resource.id
		);
		setResources([...res, thisResource]);
	};

	const handleAddResource = () => {
		setResources([
			...resources,
			{
				type: resourceOption,
				data: resource,
				id: resource.id,
				permissions: "",
			},
		]);
	};

	const handleRemoveResource = () => {
		setResources(
			resources.filter(
				(existingRes: IResource) => existingRes.id !== resource.id
			)
		);
	};

	const handleSubmit = () => {
		handleAddCondition({ ...condition, resources });
		setCondition(defaultCondition);
		setResourceOption(defaultResourceOption);
		setResource(defaultResource);
		setResources([]);
		handleClose();
	};

	return (
		<>
			<Fab color="primary" size="small" onClick={() => setOpen(true)}>
				<Add />
			</Fab>
			<div>
				<Drawer
					anchor={"right"}
					open={open}
					onClose={handleClose}
					classes={{ paper: classes.drawerPaper }}
				>
					{/* //.     Header       */}
					<Toolbar>
						<Typography
							variant="h6"
							style={{ display: "flex", alignItems: "center" }}
						>
							<IconButton onClick={() => handleClose()}>
								<ArrowLeft />
							</IconButton>
							Add Condition
						</Typography>
						<span style={{ display: "flex", flexGrow: 1 }} />
						<Button
							disabled={Boolean(!resources.length || !condition.name)}
							color="success"
							variant="contained"
							onClick={() => handleSubmit()}
						>
							Submit
						</Button>
					</Toolbar>

					{/* Loading */}
					{Boolean(buildingListIds?.length !== buildingStoreIds?.length) ? (
						<LinearProgressWithLabel
							variant="determinate"
							value={fetchProgress}
						/>
					) : (
						<Divider />
					)}

					<Section style={{ border: "none" }}>
						{/* //.     Instructions         */}
						<div className={classes.section}>
							<Grid container>
								<Grid item xs={6} style={{ paddingRight: "1rem" }}>
									<div>
										<TextField
											required
											fullWidth
											id="outlined-basic"
											label="Role Name"
											variant="outlined"
											name="name"
											value={condition.name}
											onChange={handleChangeText}
											margin="dense"
										/>
									</div>
								</Grid>
								<Grid item xs={6}>
									<div className={classes.section}>
										<TextField
											fullWidth
											id="description-basic"
											label="Description"
											variant="outlined"
											name="description"
											value={condition.description}
											onChange={handleChangeText}
											multiline
											margin="dense"
										/>
									</div>
								</Grid>
							</Grid>

							<Typography variant="body1" paragraph>
								Create a role with permissions to assign a user to,
								<Link>learn more</Link>. If you would like to add a user with
								custom permissions, <Link>click here</Link> to add a user.
							</Typography>
						</div>

						{/* //. 	Resource Options	 */}
						<div className={classes.section}>
							<TextField
								value={resourceOption?.value}
								select
								required
								fullWidth
								id="outlined-basic"
								label="Resource"
								variant="outlined"
							>
								{resourceOptions.map((option: any) => (
									<MenuItem
										key={option.value}
										value={option.value}
										onClick={() => handleChangeResourceOption(option)}
									>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</div>

						{/* //.		Sites Options 		*/}
						<Collapse in={resourceOption.value === "site"}>
							<div className={classes.section}>
								<TextField
									value={resource?.id}
									select
									required
									fullWidth
									id="outlined-basic"
									label="Select a site..."
									variant="outlined"
								>
									{sites.map((option: any) => (
										<MenuItem
											key={option.id}
											value={option.id}
											onClick={() => handleChangeResource(option)}
										>
											{option.name}
										</MenuItem>
									))}
								</TextField>
							</div>
						</Collapse>

						{/* //.		Building Options 		*/}
						<Collapse in={resourceOption.value === "building"}>
							<div className={classes.section}>
								<TextField
									value={resource?.id}
									select
									required
									fullWidth
									id="outlined-basic"
									label="Select a building..."
									variant="outlined"
								>
									{buildings.map((option: any) => (
										<MenuItem
											key={option.id}
											value={option.id}
											onClick={() => handleChangeResource(option)}
										>
											{option.name}
										</MenuItem>
									))}
								</TextField>
							</div>
						</Collapse>

						{/* //.		Floor Options 		*/}
						<Collapse in={resourceOption.value === "floor"}>
							<div className={classes.section}>
								<TextField
									value={resource?.id}
									select
									required
									fullWidth
									id="outlined-basic"
									label="Select a floor..."
									variant="outlined"
								>
									{floors?.map((option: any) => (
										<MenuItem
											key={option.id}
											value={option.id}
											onClick={() => handleChangeResource(option)}
										>
											[{option.buildingName}] {option.name}
										</MenuItem>
									))}
								</TextField>
							</div>
						</Collapse>

						<Collapse in={Boolean(resourceOption && resource)}>
							<div className={classes.section}>
								<List>
									{/* Add Condition */}
									<ListItem>
										<ListItemText
											primary="Add Resource to role"
											secondary="Add a resource to assign a permission to"
										/>
										<ListItemSecondaryAction>
											{Boolean(find(resources, ["id", resource.id])) ? (
												<Fab
													disabled={Boolean(!resource?.id)}
													size="small"
													onClick={handleRemoveResource}
													style={{ background: deepOrange[500] }}
												>
													<Delete style={{ color: "white" }} />
												</Fab>
											) : (
												<Fab
													disabled={Boolean(!resource?.id)}
													color="primary"
													size="small"
													onClick={handleAddResource}
												>
													<Add />
												</Fab>
											)}
										</ListItemSecondaryAction>
									</ListItem>

									{/* Conditions */}
									{/*  */}
								</List>
							</div>
						</Collapse>

						<Divider />
						<List>
							{/* Add Condition */}
							{orderBy(resources, ["id"]).map((res: IResource) => {
								return (
									<ResourceListItem
										key={res?.data?.id}
										resource={res}
										handleChangePermissions={handleChangePermissions}
									/>
								);
							})}

							{/* Conditions */}
							{/*  */}
						</List>
					</Section>
				</Drawer>
			</div>
		</>
	);
};
