import * as React from "react";

// MUI
import {
	DataGrid,
	GridColDef,
	GridRowParams,
	GridValueGetterParams,
} from "@mui/x-data-grid";

// Redux
import { useSelector } from "react-redux";

import { useHistory, useParams } from "react-router";
import ClientsInfoDialog from "./../Clients/ClientsInfoDialog";
import { Button } from "@mui/material";
import { useFirestore } from "react-redux-firebase";

interface IOrganizationClientsTableProps {
	id: string;
	clients: any[];
}

export const OrganizationClientsTable: React.FC<
	IOrganizationClientsTableProps
> = ({ clients, id }) => {
	const history = useHistory();
	const db = useFirestore();

	// Handlers
	const [infoData, setInfoData] = React.useState({});

	const handleClose = () => {
		setInfoData({});
	};

	const handleEnableClient = (rowClient: any) => {
		const doc = db.collection("organizations").doc(id);
		const updatedClients = clients.map((client: any) =>
			rowClient.id === client.id
				? { ...client, enabled: !Boolean(rowClient?.enabled) }
				: { ...client }
		);
		doc.update({
			clients: updatedClients,
		});
	};

	const onRowClick = (params: GridRowParams) => {
		history.push(`/dashboard/organizations/${id}/clients/${params.row.id}`, {
			title: params.row.name,
		});
	};

	// Table identity
	const columns: GridColDef[] = [
		{ field: "id", headerName: "ID", width: 150 },
		{ field: "name", headerName: "Client Name", width: 200 },
		{ field: "contact", headerName: "Contact", width: 130 },
		{
			field: "email",
			headerName: "Email",
			width: 200,
		},
		{
			field: "enabled",
			headerName: "Status",
			description: "State of client.",
			sortable: false,
			renderCell: (params: GridValueGetterParams) => {
				console.log(params);
				return (
					<Button
						size="small"
						variant="contained"
						color={params.row.enabled ? "success" : "inherit"}
						onClick={() => handleEnableClient(params.row)}
					>
						{params.row.enabled ? "Enabled" : "Disabled"}
					</Button>
				);
			},
		},
	];

	const rows: any = clients;

	return (
		<div style={{ height: 400, width: "100%" }}>
			{Boolean(clients.length) && (
				<DataGrid
					rows={rows}
					columns={columns}
					pageSize={5}
					rowsPerPageOptions={[5]}
					isCellEditable={() => false}
					disableSelectionOnClick={true}
				/>
			)}

			{/* //! Info dialog */}

			<ClientsInfoDialog infoData={infoData} handleClose={handleClose} />
		</div>
	);
};
