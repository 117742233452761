import * as React from "react";

import {
	Avatar,
	Checkbox,
	ListItem,
	ListItemAvatar,
	ListItemText,
} from "@mui/material";

import { IResource } from "./ConditionsDrawer";
import { Buildings } from "../../static/svg";
import { Business, Help, Layers, Map, Sensors } from "@mui/icons-material";
import { FormControl, FormControlLabel, FormGroup } from "@material-ui/core";

interface IResourceListItemProps {
	resource: IResource;
	handleChangePermissions: (resource: IResource) => void;
}

export const ResourceListItem: React.FC<IResourceListItemProps> = ({
	resource,
	handleChangePermissions,
}) => {
	let Icon = <Help />;
	let secondaryText = "";

	switch (resource.type.value) {
		case "site":
			Icon = <Map />;
			secondaryText = "site";
			break;

		case "building":
			Icon = <Business />;
			secondaryText = `${resource?.data?.siteName} > building`;
			break;

		case "floor":
			Icon = <Layers />;
			secondaryText = `${resource?.data?.siteName} > ${resource?.data?.buildingName} > floor`;
			break;

		case "device":
			Icon = <Sensors />;
			secondaryText = `${resource?.data?.siteName} > ${resource?.data?.buildingName} > ${resource?.data?.floorName} > device `;
			break;

		default:
			break;
	}

	if (!resource?.id || !resource?.data) {
		return (
			<ListItem>
				<ListItemText
					primary={"No Data Found"}
					secondary={"Please delete this resource"}
				/>
			</ListItem>
		);
	}

	return (
		<ListItem
			secondaryAction={
				<FormControl>
					<FormGroup aria-label="position" row>
						<FormControlLabel
							control={
								<Checkbox
									edge="end"
									onChange={() =>
										handleChangePermissions({
											...resource,
											permissions: "read",
										})
									}
									checked={resource.permissions === "read"}
								/>
							}
							label="Read"
							labelPlacement="top"
						/>
						<FormControlLabel
							control={
								<Checkbox
									edge="end"
									onChange={() =>
										handleChangePermissions({
											...resource,
											permissions: "write",
										})
									}
									checked={resource.permissions === "write"}
								/>
							}
							label="Write"
							labelPlacement="top"
						/>
						<FormControlLabel
							control={
								<Checkbox
									edge="end"
									onChange={() =>
										handleChangePermissions({
											...resource,
											permissions: "readwrite",
										})
									}
									checked={resource.permissions === "readwrite"}
								/>
							}
							label="Admin"
							labelPlacement="top"
						/>
					</FormGroup>
				</FormControl>

				// <Checkbox
				// 	edge="end"
				// 	onChange={() =>
				// 		handleChangePermissions({ ...resource, permissions: "read" })
				// 	}
				// 	checked={resource.permissions === "read"}

				// />
			}
		>
			<ListItemAvatar>
				<Avatar alt={resource.type.label}>{Icon}</Avatar>
			</ListItemAvatar>

			<ListItemText
				primary={resource.data.name}
				secondary={resource.type.value}
				secondaryTypographyProps={{
					style: {
						fontVariant: "small-caps",
					},
				}}
			/>
		</ListItem>
	);
};
