import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useAnimations = makeStyles((theme: Theme) =>
  createStyles({
    "@keyframes rotate": {
      from: {
        transform: "rotate(0deg)",
      },
      to: {
        transform: "rotate(360deg)",
      },
    },

    "@keyframes fadeIn": {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },

    "@keyframes fadeOut": {
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
    },

    "@keyframes slideIn": {
      from: {
        width: 0,
      },
      to: {
        width: "100%",
      },
    },

    "@keyframes slideOut": {
      from: {
        width: "100%",
      },
      to: {
        width: 0,
      },
    },

    "@keyframes colorTo": {
      from: {
        color: "inherit",
      },
      to: {
        color: theme.palette.primary.main,
      },
    },

    "@keyframes colorFrom": {
      from: {
        color: theme.palette.primary.main,
      },
      to: {
        color: "inherit",
      },
    },

    rotate: {
      transform: "rotate(0deg)",
      animation: `$rotate 2500ms ${theme.transitions.easing.easeInOut} 200ms infinite`,
    },

    fadeIn: {
      opacity: 0,
      animation: `$fadeIn 500ms ${theme.transitions.easing.easeIn} 100ms`,
      animationFillMode: "forwards",
      WebkitAnimationFillMode: "forwards",
    },

    fadeOut: {
      opacity: 1,
      animation: `$fadeOut 500ms ${theme.transitions.easing.easeOut} 100ms`,
      animationFillMode: "forwards",
      WebkitAnimationFillMode: "forwards",
    },

    slideIn: {
      width: 0,
      animation: `$slideIn 250ms ${theme.transitions.easing.easeIn} 100ms`,
      animationFillMode: "forwards",
      WebkitAnimationFillMode: "forwards",
    },

    slideOut: {
      width: "100%",
      animation: `$slideOut 250ms ${theme.transitions.easing.easeIn} 100ms`,
      animationFillMode: "forwards",
      WebkitAnimationFillMode: "forwards",
    },

    colorTo: {
      color: "inherit",
      animation: `$colorTo 250ms ${theme.transitions.easing.easeIn} 100ms`,
      animationFillMode: "forwards",
      WebkitAnimationFillMode: "forwards",
    },

    colorFrom: {
      color: theme.palette.primary.main,
      animation: `$colorFrom 250ms ${theme.transitions.easing.easeIn} 100ms`,
      animationFillMode: "forwards",
      WebkitAnimationFillMode: "forwards",
    },
  })
);
