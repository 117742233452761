import * as React from "react";

// MUI
import {
	Button,
	DialogContentText,
	Grid,
	LinearProgress,
	Paper,
	Typography,
} from "@mui/material";
import {
	GridCallbackDetails,
	GridRowId,
	GridSelectionModel,
	GridState,
} from "@mui/x-data-grid";

import { createStyles, makeStyles, Theme } from "@material-ui/core";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import useDeviceList from "../../hooks/useDeviceList";
import useFetchBuildings from "../../hooks/useFetchBuildings";

// Components
import DeviceDataGrid from "./DeviceDataGrid";
import DeviceFilters from "./DeviceFilters";

// UI
import { useSnackbar } from "notistack";

// Util
import { filter, find, isEmpty } from "lodash";

// Redux
import { fetchDevice } from "../../features/devices/devicesSlice";
import { useAppSelector } from "../../app/hooks";
import { MonitorReportDialog } from "./MonitorReportDialog";

interface IMonitorProps {}

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			margin: "1rem",
		},
		wrapper: {
			display: "flex",
			justifyContent: "center",
			padding: "1rem",
		},
	})
);

export const Monitor: React.FC<IMonitorProps> = (props) => {
	// List of devices
	const devices = useDeviceList();

	// Device Table data
	const [tableDevices, setTableDevices] = React.useState(devices);
	const [selectionModel, setSelectionModel] =
		React.useState<GridSelectionModel>([]);
	const [selectionData, setSelectionData] = React.useState([]);

	const handleSelection = (
		newSelectionModel: GridSelectionModel,
		details: GridCallbackDetails
	) => {
		setSelectionModel(newSelectionModel);
	};
	const handleStateChange = (state: GridState) => {};

	// Device Fetch Logic
	const dispatch = useDispatch();
	const api = useAppSelector((state: any) => state.firebase.profile.api);
	const deviceData = useAppSelector((state: any) => state.devices?.data);

	// 1 		Filter By Device Types				/1
	const [filterByType, setFilterByType]: any = React.useState([]);

	const handleFilterByType = (type: string) => {
		filterByType.includes(type)
			? setFilterByType(
					filterByType.filter((filter: string) => filter !== type)
			  )
			: setFilterByType([...filterByType, type]);
	};

	const defaultFilters = {
		building: [],
		floor: [],
		space: [],
		type: [],
	};

	// 2 		Filter Through Building Tree		/1
	const [filterBy, setFilterBy]: any = React.useState(defaultFilters);
	React.useEffect(() => {
		let updatedDevices: any = [];
		if (Boolean(filterBy.space.length)) {
			updatedDevices = filter(devices, (device: any) =>
				filterBy.space.includes(device.spaceId)
			);
		} else if (Boolean(filterBy.space.length)) {
			updatedDevices = filter(devices, (device: any) =>
				filterBy.floor.includes(device.spaceId)
			);
		} else if (Boolean(filterBy.building.length)) {
			updatedDevices = filter(devices, (device: any) =>
				filterBy.building.includes(device.buildingId)
			);
		} else {
			updatedDevices = devices;
		}

		setTableDevices(updatedDevices);
	}, [devices, filterBy, filterByType]);

	interface IDeviceType {
		name: string;
		key: string;
	}
	const deviceTypes: IDeviceType[] = [
		{ name: "IAQ", key: "virtualAirQuality" },
		{ name: "Lights", key: "virtualLightSwitch" },
		{ name: "Blinds", key: "virtualBlinds" },
		{ name: "Thermostats", key: "virtualThermostat" },
	];

	// 3 Apply filters to selected devices
	React.useEffect(() => {
		let updatedDevices: any = [];

		// 1 Filter by tree
		if (Boolean(filterBy.space.length)) {
			updatedDevices = filter(devices, (device: any) =>
				filterBy.space.includes(device.spaceId)
			);
		} else if (Boolean(filterBy.floor.length)) {
			updatedDevices = filter(devices, (device: any) =>
				filterBy.floor.includes(device.floorId)
			);
		} else if (Boolean(filterBy.building.length)) {
			updatedDevices = filter(devices, (device: any) =>
				filterBy.building.includes(device.buildingId)
			);
		} else {
			updatedDevices = devices;
		}

		// 2 Filter by device type
		if (Boolean(filterByType.length)) {
			updatedDevices = filter(updatedDevices, (device: any) =>
				filterByType.includes(device.virtualProfile)
			);
		}

		setTableDevices(updatedDevices);
	}, [devices, filterBy, filterByType]);

	// Styling
	const classes = useStyles();
	const snackbar = useSnackbar();

	// Clients
	const clients = useSelector((state: any) => state.clients);
	const didFetchClients = Boolean(clients.status === "succeeded");

	// Fetch buildings
	const didFetchBuildings = useFetchBuildings();

	// Loading
	const isLoading = !Boolean(didFetchClients && didFetchBuildings);

	// 4 Fetch Device Logic
	let [deviceFetchStatus, setDeviceFetchStatus] = React.useState("idle");

	// Fetch Device Points
	const doFetchDevice = ({
		api,
		buildingId,
		floorId,
		spaceId,
		id,
	}: {
		api: string;
		buildingId: string;
		floorId: string;
		spaceId: string;
		id: string;
	}) =>
		dispatch(
			fetchDevice({
				api,
				buildingId,
				floorId,
				spaceId,
				id,
			})
		);

	// 5 Snackbar Logic
	// Handle snackbars
	// Clients
	React.useEffect(() => {
		if (clients.status === "loading") {
			snackbar.enqueueSnackbar(`Fetching clients...`, {
				variant: "info",
				autoHideDuration: 2000,
				anchorOrigin: { vertical: "bottom", horizontal: "right" },
			});
		}

		if (didFetchClients) {
			snackbar.enqueueSnackbar(`Client fetch succeeded!`, {
				variant: "success",
				autoHideDuration: 1000,
				anchorOrigin: { vertical: "bottom", horizontal: "right" },
			});
		}
	}, [clients.status]);

	// 6 Building Fetching
	// Buildings
	React.useEffect(() => {
		if (didFetchClients) {
			if (!didFetchBuildings) {
				snackbar.enqueueSnackbar(`Fetching buildings...`, {
					variant: "info",
					autoHideDuration: 3000,
					anchorOrigin: { vertical: "bottom", horizontal: "right" },
				});
			}

			if (didFetchBuildings) {
				snackbar.enqueueSnackbar(`Buildings fetched!`, {
					variant: "success",
					autoHideDuration: 2000,
					anchorOrigin: { vertical: "bottom", horizontal: "right" },
				});
			}
		}
	}, [didFetchBuildings]);

	React.useEffect(() => {
		let data: any = [];
		let selectedIdData = {};
		selectionModel.forEach(
			(value: GridRowId, index: number, array: GridRowId[]) => {
				selectedIdData = find(tableDevices, ["id", value]);
				data.push(selectedIdData);
			}
		);
		setSelectionData(data);
	}, [selectionData.length]);

	// Scan devices
	const handleScanDevices = () => {
		setDeviceFetchStatus("fetching");

		tableDevices?.forEach((device: any) => {
			let { buildingId, floorId, spaceId, id } = device;

			doFetchDevice({
				api,
				buildingId,
				floorId,
				spaceId,
				id,
			});
		});
	};

	return (
		<div>
			{/* Loading */}
			{isLoading && <LinearProgress style={{ width: "100%" }} />}

			<Paper
				style={{ display: "flex", flexWrap: "wrap", padding: "0 2rem" }}
				elevation={0}
			>
				<Grid container className={classes.container}>
					{/* //1 Header & Scan Button */}
					<div
						style={{
							display: "flex",
							alignItems: "center",
							width: "100%",
							marginBottom: "1rem",
						}}
					>
						<Typography variant="h5" style={{ display: "flex" }}>
							Filter Available Devices
						</Typography>

						<span style={{ display: "flex", flexGrow: 1 }} />

						<Button
							disabled={isLoading}
							variant="contained"
							// onClick={() => handleScanDevices()}
							style={{ marginRight: "1rem" }}
						>
							Scan Devices
						</Button>

						{/* //. Report Dialog */}
						<MonitorReportDialog
							isLoading={isLoading}
							selectionModel={selectionModel}
						/>
					</div>

					{/* //2 Device Filters */}
					<div style={{ width: "100%" }}>
						{/* //.	By Tree */}
						<div>
							<DeviceFilters
								title={" "}
								devices={devices}
								filterBy={filterBy}
								setFilterBy={setFilterBy}
							/>
						</div>

						{/* //.	By Type */}
						<div style={{ display: "flex" }}>
							<span style={{ display: "flex", flexGrow: 1 }} />
							{deviceTypes.map((deviceType: IDeviceType) => (
								<Button
									key={deviceType.key}
									style={{ marginLeft: "1rem" }}
									variant={
										filterByType.includes(deviceType.key)
											? "contained"
											: "outlined"
									}
									onClick={() => handleFilterByType(deviceType.key)}
								>
									{deviceType.name}
								</Button>
							))}
						</div>
					</div>

					{/* //3 Data Table */}
					<DialogContentText id="alert-dialog-slide-description">
						Narrow your search by navigating to devices within the system.
					</DialogContentText>

					<DeviceDataGrid
						devices={tableDevices}
						selectionModel={selectionModel}
						handleSelection={handleSelection}
						handleStateChange={handleStateChange}
					/>
				</Grid>
			</Paper>
		</div>
	);
};
