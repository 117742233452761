import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import {
	createStyles,
	InputAdornment,
	makeStyles,
	Theme,
} from "@material-ui/core";
import { Lock, Person, SupervisorAccount } from "@material-ui/icons";

import crypto from "crypto";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		wrapper: {
			padding: "1rem",
			borderRadius: 4,
			border: "1px solid rgba(224, 224, 224, 1)",
			marginBottom: "1rem",
		},
		header: {
			display: "flex",
			alignItems: "center",
			marginBottom: "1rem",
		},
		grow: {
			display: "flex",
			flexGrow: 1,
		},
		desktopFieldPadding: {
			[theme.breakpoints.up("sm")]: {
				paddingRight: "1rem",
			},
		},
		input: {
			margin: "1rem 0",
		},
	})
);

interface IAddSiteDialog {
	handleAddSite: (data: any) => void;
}

export const AddSiteDialog = ({ handleAddSite }: IAddSiteDialog) => {
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);

	const defaultNewSite = {
		name: "",
		id: crypto.randomBytes(32).toString("hex"),
		buildings: [],
	};

	const [newSite, setNewSite] = React.useState(defaultNewSite);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleConfirmSite = () => {
		handleAddSite(newSite);
		setNewSite(defaultNewSite);
		handleClose();
	};

	const handleChange =
		(prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setNewSite({ ...newSite, [prop]: event.target.value });
		};

	return (
		<div>
			<Button variant="outlined" onClick={handleClickOpen}>
				Add Site
			</Button>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Add Site</DialogTitle>
				<DialogContent>
					<DialogContentText style={{ marginBottom: "2rem" }}>
						Add a site to your to the client. You'll be able to add buildings
						once you've created your client.
					</DialogContentText>

					<FormControl fullWidth>
						<InputLabel htmlFor="outlined-adornment-amount">
							Site Name
						</InputLabel>

						<OutlinedInput
							className={classes.input}
							fullWidth
							value={newSite.name}
							onChange={handleChange("name")}
							startAdornment={
								<InputAdornment position="start">
									<Person />
								</InputAdornment>
							}
						/>
					</FormControl>

					<FormControl fullWidth>
						<InputLabel htmlFor="outlined-adornment-amount">Site Id</InputLabel>
						<OutlinedInput
							disabled
							className={classes.input}
							fullWidth
							value={newSite.id}
							startAdornment={
								<InputAdornment position="start">
									<Lock />
								</InputAdornment>
							}
						/>
					</FormControl>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleConfirmSite}>Add</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
