import React, { useState } from "react";

import { orderBy } from "lodash";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";

// Get list of buildings across clients
const generateBuildingList: any = (clientStore: any) => {
	let buildingArray: any = [];
	Object.values(clientStore).forEach((client: any) => {
		client?.sites?.forEach((site: any) => {
			site?.buildings?.forEach((building: any) => {
				buildingArray.push({
					...building,
					clientId: client.id,
					clientName: client.name,
					siteId: site.id,
					siteName: site.name,
				});
			});
		});
	});

	return orderBy(buildingArray, ["name"], ["asc"]);
};

interface IIntervalExample {
	deviceData: any;
	route: string;
}

const useBuildingList = () => {
	const [buildingList, setBuildingList]: any = useState([]);

	const clientStore = useAppSelector((state: RootState) => state.clients.data);

	React.useEffect(() => {
		setBuildingList(generateBuildingList(clientStore));
	}, [Object.values(clientStore).length]);

	return buildingList;
};

export default useBuildingList;
