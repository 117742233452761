import * as React from "react";

import {
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Typography,
	Dialog,
	Button,
	Slide,
	LinearProgress,
	Link,
	FormLabel,
	FormGroup,
	Checkbox,
	FormControlLabel,
} from "@mui/material";

import {
	createStyles,
	FormHelperText,
	makeStyles,
	Theme,
} from "@material-ui/core";

import { MonetizationOn, Person, SupervisorAccount } from "@material-ui/icons";

import crypto from "crypto";

import { Section } from "../../components/Section/Section";
import { Title } from "../../components/Title/Title";

import { TransitionProps } from "@mui/material/transitions";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";

const separator = (num: number) => {
	var str = num.toString().split(".");
	str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return str.join(".");
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		wrapper: {
			padding: "1rem",
			borderRadius: 4,
			border: "1px solid rgba(224, 224, 224, 1)",
		},
		header: {
			display: "flex",
			alignItems: "center",
			marginBottom: "1rem",
		},
		grow: {
			display: "flex",
			flexGrow: 1,
		},
		desktopFieldPadding: {
			[theme.breakpoints.up("sm")]: {
				paddingRight: "1rem",
			},
		},
		input: {
			margin: "1rem 0",
		},
	})
);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="left" ref={ref} {...props} />;
});

interface IAddLicenseDialogProps {}

export const AddLicenseDialog: React.FC<IAddLicenseDialogProps> = (props) => {
	const classes = useStyles();
	const db = useFirestore();

	let licenses: any = useSelector(
		(state: any) => state.firestore.data?.licenses
	);

	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const defaultNewLicense = {
		enabled: false,
		name: "",
		price: 0,
		count: 0,
		pricingInterval: "",
		expires: new Date().toDateString(),
	};

	const [newLicense, setNewLicense]: any = React.useState(defaultNewLicense);

	const { pricingInterval } = newLicense;

	interface INewLicense {
		enabled: boolean;
		name: string;
		price: number;
		count: number;
		pricingInterval: string;
		expires: string;
	}

	// Dialog
	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setNewLicense(defaultNewLicense);
		setLoading(false);
		setOpen(false);
	};

	// Text Fields
	const handleChange =
		(prop: keyof INewLicense) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setNewLicense({ ...newLicense, [prop]: event.target.value });
		};

	const handleChangePricingInterval = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setNewLicense({
			...newLicense,
			pricingInterval:
				newLicense.pricingInterval === event.target.name
					? ""
					: event.target.name,
		});
	};

	const handleAddLicense = () => {
		setLoading(true);
		db.collection("licenses")
			.add({ ...newLicense })
			.then((res: any) => {
				setLoading(false);
				handleClose();
			})
			.catch((e: any) => {});
	};

	let multiplier =
		pricingInterval === "monthly"
			? 12
			: pricingInterval === "quarterly"
			? 4
			: pricingInterval === "yearly"
			? 1
			: 0;

	return (
		<div>
			{loading && <LinearProgress style={{ width: "100%" }} />}
			<div>
				<Link component="button" variant="body2" onClick={handleOpen}>
					Add License
				</Link>
			</div>
			<div style={{ margin: "1rem" }}>
				<Dialog
					onClose={handleClose}
					open={open}
					TransitionComponent={Transition}
					style={{ margin: 0 }}
				>
					<Section style={{ margin: 0 }}>
						{/* //1     Header */}
						<Title
							title="Add New License"
							button={<Button onClick={() => handleClose()}>Close</Button>}
						/>

						{/* //2     License Fields */}
						<Title title="License Options" size="small" dense />
						<Section style={{ marginBottom: "1rem" }}>
							<Grid container>
								{/* //3     Inputs */}
								<Grid
									item
									xs={12}
									sm={6}
									className={classes.desktopFieldPadding}
								>
									<Typography
										variant="overline"
										style={{ display: "flex", width: "100%" }}
										paragraph
									>
										License Details
									</Typography>

									<FormControl fullWidth>
										<InputLabel htmlFor="outlined-adornment-amount">
											License Name
										</InputLabel>
										<OutlinedInput
											disabled={loading}
											autoFocus
											className={classes.input}
											fullWidth
											value={newLicense.name}
											onChange={handleChange("name")}
											startAdornment={
												<InputAdornment position="start">
													<Person />
												</InputAdornment>
											}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Typography
										variant="overline"
										style={{ display: "flex", width: "100%" }}
										paragraph
									>
										Default Count
									</Typography>

									<FormControl fullWidth>
										<InputLabel htmlFor="outlined-adornment-amount">
											Number Available (unlimited by default)
										</InputLabel>
										<OutlinedInput
											disabled={loading}
											className={classes.input}
											fullWidth
											value={newLicense.contact}
											onChange={handleChange("count")}
											startAdornment={
												<InputAdornment position="start">#</InputAdornment>
											}
										/>
									</FormControl>
								</Grid>
							</Grid>
							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
								}}
							></div>
						</Section>

						{/* //2     License Options */}
						<Title title="License Details" size="small" dense />
						<Section style={{ margin: 0 }}>
							<Grid container>
								{/* //3     Inputs */}

								<Grid
									item
									xs={12}
									sm={6}
									className={classes.desktopFieldPadding}
								>
									<Typography
										variant="overline"
										style={{ display: "flex", width: "100%" }}
										paragraph
									>
										License Duration
									</Typography>

									<FormControl fullWidth>
										<InputLabel htmlFor="outlined-adornment-amount">
											Price
										</InputLabel>
										<OutlinedInput
											disabled={loading}
											className={classes.input}
											fullWidth
											value={newLicense.contact}
											onChange={handleChange("price")}
											startAdornment={
												<InputAdornment position="start">
													<MonetizationOn />
												</InputAdornment>
											}
										/>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={6} style={{ paddingLeft: "2rem" }}>
									<Typography
										variant="overline"
										style={{ display: "flex", width: "100%" }}
										paragraph
									>
										Pricing Interval
									</Typography>

									<FormControl component="fieldset" variant="standard">
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														checked={newLicense.pricingInterval === "monthly"}
														onChange={handleChangePricingInterval}
														name="monthly"
													/>
												}
												label="Monthly"
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={newLicense.pricingInterval === "quarterly"}
														onChange={handleChangePricingInterval}
														name="quarterly"
													/>
												}
												label="Quarterly"
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={newLicense.pricingInterval === "yearly"}
														onChange={handleChangePricingInterval}
														name="yearly"
													/>
												}
												label="Yearly"
											/>
										</FormGroup>
										{Boolean(
											newLicense.price && newLicense.pricingInterval
										) && (
											<>
												<FormHelperText>
													{`Yearly estimate / license: $${separator(
														newLicense.price * multiplier
													)}`}
													<br />
													{Boolean(newLicense.count) &&
														`Yearly / max licenses: $${separator(
															newLicense.count * (newLicense.price * multiplier)
														)}`}
												</FormHelperText>
											</>
										)}
									</FormControl>
								</Grid>
							</Grid>
							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
								}}
							></div>
						</Section>
					</Section>

					<Button
						onClick={() => handleAddLicense()}
						style={{ borderRadius: 0 }}
						variant="contained"
						color="primary"
						disabled={!newLicense.name || !newLicense.price || loading}
					>
						Add License
					</Button>
				</Dialog>
			</div>
		</div>
	);
};
