import * as React from "react";

import { store } from "../../firebase/firebase";

// MUI
import { createStyles, makeStyles, Theme } from "@material-ui/core";

import {
	Dialog,
	Skeleton,
	Zoom,
	FormControl,
	Grid,
	TextField,
	Typography,
	Link,
	useTheme,
	Button,
	LinearProgress,
} from "@mui/material";

import { TransitionHandlerProps } from "@mui/material/transitions";

// Components
import { Section } from "../../components/Section/Section";
import { Title } from "../../components/Title/Title";
import ColorPicker from "material-ui-color-picker";

// Transitions
const Transition = React.forwardRef(function Transition(
	props: TransitionHandlerProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Zoom ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		wrapper: {
			padding: "1rem",
			borderRadius: 4,
			border: "1px solid rgba(224, 224, 224, 1)",
			marginBottom: "1rem",
		},
		header: {
			display: "flex",
			alignItems: "center",
			marginBottom: "1rem",
		},
		grow: {
			display: "flex",
			flexGrow: 1,
		},
		desktopFieldPadding: {
			[theme.breakpoints.up("sm")]: {
				paddingRight: "1rem",
			},
		},
	})
);

interface IAddWhitelabelDialogProps {}

export const AddWhitelabelDialog: React.FC<IAddWhitelabelDialogProps> = () => {
	const classes = useStyles();

	const theme = useTheme();

	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const defaultNewWhitelabel = {
		name: "",
		tagline: "",
		description: "",
		primary: "",
		secondary: "",
		accent: "",
		icon: "",
		logo: "",
	};

	const [newWhitelabelData, setNewWhitelabelData]: any =
		React.useState(defaultNewWhitelabel);

	const handleChange =
		(prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setNewWhitelabelData({
				...newWhitelabelData,
				[prop]: event.target.value,
			});
		};

	const onClose = () => {
		setNewWhitelabelData(defaultNewWhitelabel);
		setOpen(false);
	};

	const handleCreate = () => {
		setLoading(true);
		store
			.collection("whitelabels")
			.doc()
			.set({
				...newWhitelabelData,
			})
			.then(() => {
				setLoading(false);
				onClose();
			});
	};

	const handleChangeColor = (prop: string, value: string) => {
		if (value) {
			setNewWhitelabelData({ ...newWhitelabelData, [prop]: value });
		}
	};

	console.log(newWhitelabelData);

	return (
		<>
			<Link
				component="button"
				variant="body2"
				onClick={() => {
					setOpen(true);
				}}
			>
				Create New
			</Link>

			<Dialog
				onClose={onClose}
				open={open}
				TransitionComponent={Transition}
				keepMounted
			>
				{loading && <LinearProgress style={{ width: "100%" }} />}

				<div style={{ margin: "2rem" }}>
					{/* //.     Header      */}
					<Title
						title="Create Whitelabel"
						button={
							<Button variant="outlined" onClick={onClose}>
								Close
							</Button>
						}
					/>

					<Title title="Brand Information" size="small" dense />
					<Section>
						<Grid container>
							{/* //3     Inputs */}
							<Grid item xs={12} sm={6} className={classes.desktopFieldPadding}>
								<Typography
									variant="overline"
									style={{ display: "flex", width: "100%" }}
								>
									Text
								</Typography>

								<FormControl fullWidth style={{ marginBottom: ".75rem" }}>
									<TextField
										size="small"
										variant="outlined"
										fullWidth
										value={newWhitelabelData.name}
										onChange={handleChange("name")}
										helperText="Whitelabel Name"
										disabled={loading}
									/>
								</FormControl>

								<FormControl fullWidth style={{ marginBottom: ".75rem" }}>
									<TextField
										size="small"
										variant="outlined"
										fullWidth
										value={newWhitelabelData.tagline}
										onChange={handleChange("tagline")}
										helperText="Tagline (optional)"
										disabled={loading}
									/>
								</FormControl>

								<FormControl fullWidth style={{ marginBottom: ".75rem" }}>
									<TextField
										size="small"
										variant="outlined"
										fullWidth
										value={newWhitelabelData.description}
										onChange={handleChange("description")}
										helperText="Description (optional)"
										disabled={loading}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography
									variant="overline"
									style={{ display: "flex", width: "100%" }}
								>
									Colors
								</Typography>

								<FormControl fullWidth style={{ marginBottom: ".75rem" }}>
									<ColorPicker
										disabled
										name="color"
										size="small"
										variant="outlined"
										defaultValue={"\u2589 PRIMARY"}
										onChange={(color: any) =>
											handleChangeColor("primary", color)
										}
										helperText="Primary"
										InputProps={{
											style: {
												fontWeight: 500,
												color: newWhitelabelData.primary,
											},
										}}
									>
										{newWhitelabelData.primary}
									</ColorPicker>
								</FormControl>

								<FormControl fullWidth style={{ marginBottom: ".75rem" }}>
									<ColorPicker
										disabled
										name="color"
										size="small"
										variant="outlined"
										defaultValue={"\u2589 SECONDARY"}
										onChange={(color: any) =>
											handleChangeColor("secondary", color)
										}
										helperText="Secondary"
										InputProps={{
											style: {
												fontWeight: 500,
												color: newWhitelabelData.secondary,
											},
										}}
									/>
								</FormControl>

								<FormControl fullWidth style={{ marginBottom: ".75rem" }}>
									<ColorPicker
										disabled
										name="color"
										size="small"
										variant="outlined"
										defaultValue={"\u2589 ACCENT"}
										onChange={(color: any) =>
											handleChangeColor("accent", color)
										}
										helperText="Accent"
										InputProps={{
											style: {
												fontWeight: 500,
												color: newWhitelabelData.accent,
											},
										}}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Section>

					<Title title="Images" size="small" dense />
					<Section>
						<Grid container>
							<Grid item sm={6} xs={12}>
								<div>
									<Typography variant="overline">Icon</Typography>
									<Skeleton height={100} />
								</div>
								<FormControl fullWidth style={{ marginBottom: ".75rem" }}>
									<TextField
										size="small"
										variant="outlined"
										fullWidth
										value={newWhitelabelData.icon}
										onChange={handleChange("icon")}
										helperText="Icon URL"
										disabled={loading}
									/>
								</FormControl>
							</Grid>
							<Grid item sm={6} xs={12} style={{ paddingLeft: "1rem" }}>
								<div>
									<Typography variant="overline">Logo</Typography>
									<Skeleton height={100} />
								</div>
								<FormControl fullWidth style={{ marginBottom: ".75rem" }}>
									<TextField
										size="small"
										variant="outlined"
										fullWidth
										value={newWhitelabelData.logo}
										onChange={handleChange("logo")}
										helperText="Logo URL"
										disabled={loading}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Section>

					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							variant="contained"
							color="success"
							disabled={
								loading || !newWhitelabelData.name || !newWhitelabelData.primary
							}
							onClick={handleCreate}
						>
							Create
						</Button>
					</div>
				</div>
			</Dialog>
		</>
	);
};
