import * as React from "react";

import {
	DataGrid,
	GridColDef,
	GridRowParams,
	GridValueGetterParams,
} from "@mui/x-data-grid";

import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { useHistory } from "react-router";
import { Typography, useTheme } from "@mui/material";

import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

interface IOrganizationsTableProps {
	rowClick?: (params: GridRowParams) => any;
}

export const OrganizationsTable: React.FC<IOrganizationsTableProps> = ({
	rowClick,
}) => {
	const history = useHistory();
	const theme = useTheme();

	const licenses = useAppSelector(
		(state: RootState) => state.firestore?.data?.licenses
	);

	useFirestoreConnect(["organizations"]);
	let organizations: any = useSelector(
		(state: any) => state.firestore.data?.organizations
	);

	let organizationValues: any = [];
	if (organizations && !isEmpty(organizations)) {
		organizationValues = Object.keys(organizations).map(
			(organization: any) => ({
				...organizations[organization],
				id: organization,
			})
		);
	}

	// Table identity
	const columns: GridColDef[] = [
		{ field: "name", headerName: "Organization Name", width: 200 },
		{
			field: "license",
			headerName: "License",
			width: 130,
			description: "Current list of licenses.",
			sortable: false,
			renderCell: (params: GridValueGetterParams) => {
				return params.row.license?.enabled ? (
					<Typography>
						{licenses?.[params.row.license.id].name || ""}
					</Typography>
				) : (
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							flexDirection: "column",
						}}
					>
						<Typography style={{ color: theme.palette.text.disabled }}>
							{licenses?.[params.row.license.id].name || ""}
						</Typography>
						<span
							style={{
								display: "flex",
								flexWrap: "wrap",
								color: theme.palette.text.disabled,
							}}
						>
							[Disabled]
						</span>
					</div>
				);
			},
		},
		{
			field: "clients",
			align: "center",
			headerName: "Clients",
			description: "Current list of clients.",
			sortable: false,
			renderCell: (params: GridValueGetterParams) => {
				let enabled = [],
					disabled = [];
				params.row.clients.forEach((client: any) =>
					!client.enabled ? disabled.push(client) : enabled.push(client)
				);

				return (
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography>{enabled.length}</Typography>
						{Boolean(disabled.length) && (
							<span
								style={{
									color: theme.palette.text.disabled,
								}}
							>
								{disabled.length} Disabled
							</span>
						)}
					</div>
				);
			},
		},
		{
			align: "center",
			field: "users",
			headerName: "Users",
			description: "Current list of users.",
			sortable: false,
			valueGetter: (params: GridValueGetterParams) =>
				`${params.row.users?.length} `,
		},
		{
			field: "contact",
			headerName: "Contact",
			description: "Current list of clients.",
			sortable: false,
			width: 200,
			valueGetter: (params: GridValueGetterParams) => `${params.row?.email} `,
		},
		{ field: "id", headerName: "ID", width: 200 },
	];

	let rows: any = organizationValues;

	// Handlers
	const onRowClick = (params: GridRowParams) => {
		rowClick
			? rowClick(params)
			: history.push(`/dashboard/organizations/${params.row.id}`, {
					title: params.row.name,
			  });
	};

	if (!organizationValues.length) return <span />;

	return (
		<div style={{ height: 350 }}>
			<DataGrid
				autoHeight
				rows={rows}
				columns={columns}
				pageSize={5}
				rowsPerPageOptions={[5]}
				isCellEditable={() => false}
				disableSelectionOnClick={true}
				onRowClick={onRowClick}
			/>
		</div>
	);
};
