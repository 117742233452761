import * as React from "react";

import { Switch } from "react-router";
import PrivateRoute from "./components/PrivateRoute";

import { Permissions } from "../pages/Permissions/Permissions";
import { OrganizationPermissions } from "../pages/Permissions/OrganizationPermissions";

interface IPermissionsRouterProps {}

export const PermissionsRouter: React.FC<IPermissionsRouterProps> = (props) => {
	return (
		<div>
			<Switch>
				<PrivateRoute exact path="/dashboard/permissions">
					<Permissions />
				</PrivateRoute>
				<PrivateRoute exact path="/dashboard/permissions/:id">
					<OrganizationPermissions />
				</PrivateRoute>
			</Switch>
		</div>
	);
};
