import React from "react";

// Firebase
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

// Util
import { isEmpty } from "lodash";

const useGetOrganizations = () => {
	const [organizations, setOrganizations]: any = React.useState([]);

	useFirestoreConnect(["organizations"]);
	let firestoreOrganizations: any =
		useSelector((state: any) => state.firestore.data?.organizations) || {};

	React.useEffect(() => {
		if (firestoreOrganizations && !isEmpty(firestoreOrganizations)) {
			let organizationValues = [];
			organizationValues = Object.keys(firestoreOrganizations).map(
				(organization: any) => ({
					...firestoreOrganizations[organization],
					id: organization,
				})
			);
			setOrganizations(organizationValues);
		}
	}, [firestoreOrganizations]);

	return organizations;
};

export default useGetOrganizations;
