import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		glass: {
			backgroundColor: "rgba(255, 255, 255, .4)",
			backdropFilter: "blur(7px)",
		},
		fullWidth: {
			width: "100%",
		},
		round: {
			borderRadius: 20,
		},
		borders: {
			borderLeft: "solid 1px rgba(255, 255, 255, .3)",
			borderTop: "solid 1px rgba(255, 255, 255, .3)",
		},
		effects: {
			backgroundImage:
				"linear-gradient(to bottom right, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0))",
			boxShadow: "10px 10px 10px rgba(30, 30, 30, .1)",
		},
	})
);

interface IGlassPaperProps {
	square?: boolean;
	fullWidth?: boolean;
	style?: any;
	className?: any;
	disableBorders?: boolean;
	disableEffects?: boolean;
}

export const GlassPaper: React.FC<IGlassPaperProps> = (props) => {
	const classes = useStyles();

	// Props
	const {
		square,
		fullWidth,
		disableBorders = false,
		disableEffects = false,
		...rest
	} = props;

	return (
		<Box
			className={clsx(classes.glass, {
				[classes.round]: !square,
				[classes.fullWidth]: fullWidth,
				[classes.borders]: !Boolean(disableBorders),
				[classes.effects]: !Boolean(disableEffects),
			})}
			{...rest}
		/>
	);
};
