import React from "react";
import { useAppSelector } from "../app/hooks";

// Simple loading
export const useLoading = (type: "clients" | "buildings") => {
	const clientsStatus = useAppSelector((state: any) => state.clients.status);
	const buildingsStatus = useAppSelector(
		(state: any) => state.buildings.status
	);

	return type === "clients"
		? clientsStatus === "loading" || !Boolean(clientsStatus)
		: buildingsStatus === "loading" || !Boolean(buildingsStatus);
};
