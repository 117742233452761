import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { functions } from "../../firebase/firebase";

const fetchEndpointPublic = functions.httpsCallable("fetchEndpointPublic");

export const fetchPublic: any = createAsyncThunk(
	"public/fetchPublic",
	async ({
		type,
		id,
		name,
	}: {
		type: "clients" | "buildings" | "devices" | "history";
		id?: string;
		name?: string;
	}) => {
		let response: any;

		// Determine type of resource to fetch publicly
		if (type === "clients") {
			response = await fetchEndpointPublic({ route: "/clients" });
		}

		if (type === "buildings") {
			response = await fetchEndpointPublic({ route: `/building/${id}` });
		}

		if (type === "devices") {
			response = await fetchEndpointPublic({ route: `/device/${id}` });
		}

		if (type === "history") {
			response = await fetchEndpointPublic({
				route: `/history/point/${id}`,
			});
		}

		const parsedResponse = JSON.parse(response.data);

		// Check if data coming back is valid
		let isValidClient = Boolean(
			Array.isArray(parsedResponse) && parsedResponse.length
		);
		let isValidBuilding = parsedResponse?.id;

		// Store valid data
		if (isValidClient || isValidBuilding) {
			if (type === "clients") {
				const callback = (acc: any, cv: any) => {
					acc[cv.client.id] = cv.client;
					return acc;
				};

				return parsedResponse.reduce(callback, {});
			}

			if (type === "buildings" || type === "devices") {
				return parsedResponse;
			}

			if (type === "history") {
				return Object.assign(parsedResponse, { id, name });
			}
		} else {
			return {};
		}
	}
);

export const publicSlice = createSlice({
	name: "public",
	initialState: {
		clients: {},
		buildings: {},
		devices: {},
		history: {},
		status: "",
		error: "",
	},
	reducers: {},
	extraReducers: {
		[fetchPublic.pending]: (state, action) => {
			state.status = "loading";
		},
		[fetchPublic.fulfilled]: (state: any, action) => {
			state.status = "succeeded";

			if (action.meta.arg.type === "clients") {
				state.clients = action.payload;
			}

			if (action.meta.arg.type === "buildings") {
				state.buildings[action.meta.arg.id] = action.payload;
			}

			if (action.meta.arg.type === "devices") {
				state.devices[action.meta.arg.id] = action.payload;
			}

			if (action.meta.arg.type === "history") {
				state.history[action.meta.arg.id] = action.payload;
			}
		},
		[fetchPublic.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
	},
});

export default publicSlice.reducer;
