import * as React from "react";

// Firestore
import { store } from "../../firebase/firebase";
import firestore, { useFirestoreConnect } from "react-redux-firebase";

// MUI
import {
	Button,
	Dialog,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	LinearProgress,
	Link,
	List,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Slide,
	TextField,
	Typography,
} from "@mui/material";

import { TransitionProps } from "@mui/material/transitions";

import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";

// Icons
import {
	AlternateEmail,
	Lock,
	Person,
	Phone,
	Refresh,
	SupervisorAccount,
} from "@material-ui/icons";

import crypto from "crypto";

import { useHistory } from "react-router";

// Colors
import {
	green,
	indigo,
	lightBlue,
	lightGreen,
	orange,
	red,
} from "@mui/material/colors";

// Components
import { Title } from "../../components/Title/Title";
import { Section } from "../../components/Section/Section";
import { AddWhitelabelDialog } from "../Whitelabels/AddWhitelabelDialog";
import { useSelector } from "react-redux";
import { find, isEmpty } from "lodash";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="left" ref={ref} {...props} />;
});

interface IAddOrganization {
	open: boolean;
	onClose: () => void;
	onSave: (values: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			margin: "2rem",
		},
		// App Bar
		wrapper: {
			padding: "1rem",
			borderRadius: 4,
			border: "1px solid rgba(224, 224, 224, 1)",
			marginBottom: "1rem",
		},
		header: {
			display: "flex",
			alignItems: "center",
			marginBottom: "1rem",
		},
		grow: {
			display: "flex",
			flexGrow: 1,
		},
		desktopFieldPadding: {
			[theme.breakpoints.up("sm")]: {
				paddingRight: "1rem",
			},
		},
		input: {
			margin: "1rem 0",
		},
	})
);

const ColorBox = ({ color }: any) => {
	return (
		<div
			style={{
				background: color,
				height: 15,
				width: 15,
				borderRadius: 3,
				marginRight: ".5rem",
			}}
		>
			<span />
		</div>
	);
};

export const AddOrganizationDialog = (props: IAddOrganization) => {
	const { onClose, open } = props;

	const theme = useTheme();

	const classes = useStyles();
	const history = useHistory();

	useFirestoreConnect(["whitelabels"]);
	let whitelabels: any = useSelector(
		(state: any) => state.firestore.data?.whitelabels
	);

	interface IWhitelabel {
		accent: string;
		description: string;
		id: string;
		name: string;
		primary: string;
		secondary: string;
		tagline: string;
	}

	let whitelabelValues: any = [];
	if (whitelabels && !isEmpty(whitelabels)) {
		whitelabelValues = Object.keys(whitelabels).map((wl: any) => ({
			...whitelabels[wl],
			id: wl,
		}));
	}

	useFirestoreConnect(["licenses"]);
	let licenses: any = useSelector(
		(state: any) => state.firestore.data?.licenses
	);

	let licenseValues: any = [];
	if (licenses && !isEmpty(licenses)) {
		licenseValues = Object.keys(licenses).map((license: any) => ({
			...licenses[license],
			id: license,
		}));
	}

	const defaultNewOrganization = {
		id: crypto.randomBytes(16).toString("hex"),
		name: "",

		email: "",
		contact: "",
		phoneNumber: "",
		whitelabel: {
			id: "",
			enabled: true,
		},
		license: {
			id: "",
			enabled: true,
		},
		products: [],
		users: [],
		clients: [],
	};

	const [newOrganizationData, setNewOrganizationData]: any = React.useState(
		defaultNewOrganization
	);

	const handleClose = () => {
		setNewOrganizationData(defaultNewOrganization);
		onClose();
	};

	const handleChangeLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewOrganizationData({
			...newOrganizationData,
			license: { id: event.target.value, enabled: true },
		});
	};

	const handleAssignRandomKey = () => {
		setNewOrganizationData({
			...newOrganizationData,
			id: crypto.randomBytes(16).toString("hex"),
		});
	};

	interface INewOrganizationData {
		id: string;
		name: string;
		license: {
			id: string;
			enabled: boolean;
		};
		email: string;
		contact: string;
		phoneNumber: string;
		whitelabel: {
			id: string;
			enabled: boolean;
		};
	}

	const handleChange =
		(prop: keyof INewOrganizationData) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setNewOrganizationData({
				...newOrganizationData,
				[prop]: event.target.value,
			});
		};

	// Handlers
	const handleChangeWhitelabel = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setNewOrganizationData({
			...newOrganizationData,
			whitelabel: { id: event.target.value, enabled: true },
		});
	};

	const selectedWhitelabel =
		find(whitelabelValues, ["id", newOrganizationData.whitelabel]) || {};

	const [loading, setLoading] = React.useState(false);
	const handleSubmit = () => {
		setLoading(true);
		store
			.collection("organizations")
			.doc()
			.set({
				...newOrganizationData,
			})
			.then((res: any) => {
				setLoading(false);
				setNewOrganizationData(defaultNewOrganization);
				onClose();
			})
			.catch((e: any) => {
				console.log(e);
			});
	};

	return (
		<Dialog
			fullScreen
			onClose={onClose}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			PaperProps={{
				style: {
					backgroundColor: theme.palette.background.default,
				},
			}}
		>
			{loading && <LinearProgress style={{ width: "100%" }} />}

			<div className={classes.container}>
				{/* //.     Header      */}
				<Title
					title="Add New Organization"
					button={
						<Button
							variant="outlined"
							onClick={() => handleClose()}
							disabled={loading}
						>
							Close
						</Button>
					}
				/>

				{/* //1     Name and ID      */}
				<Section>
					<Grid container>
						{/* //.     Organization Name    */}
						<Grid item xs={12} sm={6} className={classes.desktopFieldPadding}>
							<FormControl fullWidth>
								<InputLabel htmlFor="outlined-adornment-amount">
									Name
								</InputLabel>
								<OutlinedInput
									autoFocus
									className={classes.input}
									fullWidth
									value={newOrganizationData.name}
									onChange={handleChange("name")}
									startAdornment={
										<InputAdornment position="start">
											<SupervisorAccount />
										</InputAdornment>
									}
								/>
							</FormControl>
						</Grid>

						{/* //.     ID    */}
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<InputLabel htmlFor="outlined-adornment-amount">Id</InputLabel>
								<OutlinedInput
									className={classes.input}
									fullWidth
									value={newOrganizationData.id}
									onChange={handleChange("id")}
									startAdornment={
										<InputAdornment position="start">
											<Lock />
										</InputAdornment>
									}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => handleAssignRandomKey()}
											>
												{<Refresh />}
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</Section>

				{/* //2     License      */}
				<Title title="License" size="small" dense />

				<Section>
					<Grid container style={{ alignItems: "center" }}>
						<Grid item xs={12} sm={6} className={classes.desktopFieldPadding}>
							<List>
								<ListItemText primary="License Type" />
								<ListItemSecondaryAction>
									<TextField
										select
										label=""
										value={newOrganizationData.license?.id}
										helperText="Current license applied"
										onChange={handleChangeLicense}
									>
										{licenseValues.map((license: any, idx: number) => (
											<MenuItem
												key={`${license.name}_${license.price}_${idx}`}
												value={license.id}
											>
												{license.name}
											</MenuItem>
										))}
									</TextField>
								</ListItemSecondaryAction>
							</List>
						</Grid>

						<Grid item xs={12} sm={6}>
							<List>
								<ListItemText
									primary={
										<div style={{ display: "flex", alignItems: "center" }}>
											<span style={{ marginRight: "1rem" }}>Whitelabel </span>
											<ColorBox color={selectedWhitelabel?.primary || "#fff"} />
											<ColorBox
												color={selectedWhitelabel?.secondary || "#fff"}
											/>
											<ColorBox color={selectedWhitelabel?.accent || "#fff"} />
										</div>
									}
									secondary={<AddWhitelabelDialog />}
								/>
								<ListItemSecondaryAction>
									<TextField
										select
										label=""
										value={newOrganizationData.whitelabel?.id}
										helperText="Current whitelabel applied"
										onChange={handleChangeWhitelabel}
									>
										{whitelabelValues.map((option: any) => (
											<MenuItem key={option.name} value={option.id}>
												{option.name}{" "}
											</MenuItem>
										))}
									</TextField>
								</ListItemSecondaryAction>
							</List>
						</Grid>
					</Grid>
				</Section>

				{/* //2     Contact Fields */}
				<Title title="Contact Details" size="small" dense />
				<Section>
					<Grid container>
						{/* //3     Inputs */}

						<Grid item xs={12} sm={6}>
							<Typography
								variant="overline"
								style={{ display: "flex", width: "100%" }}
								paragraph
							>
								Contact Information
							</Typography>

							<FormControl fullWidth>
								<InputLabel htmlFor="outlined-adornment-amount">
									Name
								</InputLabel>
								<OutlinedInput
									className={classes.input}
									fullWidth
									value={newOrganizationData.contact}
									onChange={handleChange("contact")}
									startAdornment={
										<InputAdornment position="start">
											<Person />
										</InputAdornment>
									}
								/>
							</FormControl>
							<FormControl fullWidth>
								<InputLabel htmlFor="outlined-adornment-amount">
									Email
								</InputLabel>

								<OutlinedInput
									className={classes.input}
									fullWidth
									value={newOrganizationData.email}
									onChange={handleChange("email")}
									startAdornment={
										<InputAdornment position="start">
											<AlternateEmail />
										</InputAdornment>
									}
								/>
							</FormControl>

							<FormControl fullWidth>
								<InputLabel htmlFor="outlined-adornment-amount">
									Phone Number
								</InputLabel>

								<OutlinedInput
									className={classes.input}
									fullWidth
									value={newOrganizationData.phoneNumber}
									onChange={handleChange("phoneNumber")}
									startAdornment={
										<InputAdornment position="start">
											<Phone />
										</InputAdornment>
									}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</Section>

				<div
					style={{ display: "flex", justifyContent: "center", width: "100%" }}
				>
					<Button
						size="large"
						variant="contained"
						color="success"
						disabled={!newOrganizationData.name || !newOrganizationData.id}
						onClick={handleSubmit}
					>
						Create New Organization
					</Button>
				</div>
			</div>
		</Dialog>
	);
};
