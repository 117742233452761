import * as React from "react";

// MUI
import { Button } from "@mui/material";

// Components (Common)
import { GoBack } from "../components/GoBack/GoBack";

// Routing
import PrivateRoute from "./components/PrivateRoute";
import { Switch } from "react-router";

// Components
import { AddOrganizationDialog } from "../pages/Organizations/AddOrganizationDialog";
import { OrganizationPage } from "../pages/Organizations/OrganizationPage";
import { OrganizationsTable } from "../pages/Organizations/OrganizationsTable";

interface IOrganizationsRouterProps {}

export const OrganizationsRouter: React.FC<IOrganizationsRouterProps> = (
	props
) => {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = (values: any) => {
		//
	};

	return (
		<div>
			<GoBack />
			<Switch>
				<PrivateRoute exact path="/dashboard/organizations">
					<div style={{ marginBottom: "1rem" }}>
						<Button
							variant="contained"
							color="primary"
							onClick={handleClickOpen}
						>
							Add Organization
						</Button>

						{/* Dialog */}
						<AddOrganizationDialog
							open={open}
							onClose={handleClose}
							onSave={handleSave}
						/>
					</div>
					<div>
						<OrganizationsTable />
					</div>
				</PrivateRoute>

				<PrivateRoute exact path="/dashboard/organizations/:id">
					<div>
						<OrganizationPage />
					</div>
				</PrivateRoute>
			</Switch>
		</div>
	);
};
